import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    shimmerDiv: {
      position: "relative",
      width: "100%",
    },
    notLoaded: {
      display: "none !important",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
