import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button } from "@my-scoot/component-library-legacy";
import classnames from "classnames";

import useStyles from "./EmptyListing.styles";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { useToggleState } from "utils/hooks";
import PreviewModal from "./components/PreviewModal/PreviewModal";

const EmptyListing = ({
  logo,
  content_title,
  primary_cta,
  secondary_cta,
  preview_image,
  children,
  classes: overriding_classes = {},
}) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const [is_modal_active, show_modal, hide_modal] = useToggleState(false);

  return (
    <div
      className={classnames(classes.container, overriding_classes.container)}
    >
      {/* {page_title && <div className={classes.page_title}>{page_title}</div>} */}

      <div
        className={classnames(
          classes.content_wrapper,
          overriding_classes.content_wrapper
        )}
      >
        {logo}

        {content_title && (
          <div
            className={classnames(
              classes.content_title,
              overriding_classes.content_title
            )}
          >
            {content_title}
          </div>
        )}

        {children}

        {(primary_cta || secondary_cta) && (
          <div
            className={classnames(
              classes.cta_footer,
              overriding_classes.cta_footer
            )}
          >
            {primary_cta && (
              <Button className={classes.primary_cta} {...primary_cta} />
            )}
            {secondary_cta && preview_image && (
              <Button
                color="subtlePrimary"
                onClick={show_modal}
                {...secondary_cta}
              />
            )}
          </div>
        )}
      </div>
      {preview_image && (
        <PreviewModal
          open={is_modal_active}
          onClose={hide_modal}
          preview_image={preview_image}
        />
      )}
    </div>
  );
};

export default withComponentLibraryTheme(EmptyListing);
