import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: theme?.palette?.primary?.shade_50,
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: ({ isDesktop }) =>
        isDesktop ? "24px 24px 32px 24px" : "16px 16px 32px 16px",
      alignItems: "center",
    },
    drawerFooterBtnLabel: {
      display: "flex",
      justifyContent: "space-between",
    },
    drawerFooterBtnContent: {
      flex: "1",
      marginLeft: "-24px",
    },
    copyBtn: {
      borderRadius: "12px",
      background: theme?.palette?.basic?.white,
      boxShadow: "0px 2px 0px rgba(14, 13, 12, 0.14)",
    },
    linkWrapper: {
      textAlign: "center",
      overflowWrap: "anywhere",
    },
    link: {
      color: `${theme?.palette?.primary?.main} !important`,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17px",
    },
    platformContainer: {
      width: "100%",
      border: `1.5px solid ${theme?.palette?.primary?.main}`,
      boxShadow: "0px 2px 0px rgba(14, 13, 12, 0.14)",
      borderRadius: "12px",
      background: theme?.palette?.basic?.white,
      padding: "16px 14px",
    },
    platformContainerTitle: {
      textAlign: "center",
      color: theme?.palette?.primary?.main,
      marginBottom: "14px",
      fontSize: "16px",
      fontWeight: 500,
    },
    platforms: {
      borderTop: `1.29px solid ${theme?.palette?.secondary?.shade_100}`,
      paddingTop: "14px",
      display: "flex",
      justifyContent: "center",
      gap: "8px",
    },
    platformIcon: {
      textAlign: "center",
      fontSize: "max(120px, 14.8vh)",
      width: "30px",
      minWidth: "40px",
      minHeight: "40px",
      cursor: "pointer",
      transition: "0.1s ease-in all",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  }),
  { name: "CreatorTool" }
);
