import React from "react";
import { is_empty } from "utils/validations";
import { Button, Dropdown } from "@my-scoot/component-library-legacy";

export const TableActions = ({ tableActions }) => {
  const [selectedDropdownValue, setSelectedDropdownValue] =
    React.useState(null);

  if (is_empty(tableActions)) return null;

  const handleDropwdownItemClick = (value) => {
    setSelectedDropdownValue(value);
    tableActions[value].onClick();
  };

  if (tableActions.length === 1) {
    // if one action only then show a button only
    const data = tableActions[0];
    return (
      <>
        <Button onClick={data.onClick} color="primary" size="medium">
          {!is_empty(data?.text) && data?.text}
        </Button>
      </>
    );
  }
  // else show a dropdown
  else {
    const data = tableActions.map((item, index) => ({
      id: index,
      label: item.text,
      value: index,
    }));
    return (
      <Dropdown
        options={data}
        placeholder={"Bulk Actions"}
        handleChange={handleDropwdownItemClick}
        value={selectedDropdownValue}
      />
    );
  }
};
