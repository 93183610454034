import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      margin: "15px 0 0 0",
      display: "flex",
      gap: ({ is_desktop }) => (is_desktop ? "12px" : "8px"),
    },
    replyAvatar: {
      width: "37px",
      height: "37px",
      fontSize: "13px",
    },
    rightSection: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      background: "#ECEBE9",
      borderRadius: "10px",
      padding: "12px 12px 20px 12px",
    },
    iconButton: {
      padding: 0,
      "&:hover": {
        cursor: "pointer",
      },
    },
    popoverPaper: {
      boxShadow: "0px 4px 4px rgba(14, 13, 12, 0.16)",
      borderRadius: "5px",
      background: theme.palette.basic.white,
      padding: "8px 0",
    },
    actionItem: {
      fontSize: "14px",
      lineHeight: "17px",
      padding: "4px 9px",
      '&:hover': {
        background: "#E7E6E4",
        cursor: "pointer",
      },
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
