import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    repliesContainer: {},
    repliesHeader: {
      margin: "8px 12px 14px 12px",
      display: "flex",
      alignItems: "center",
      gap: "16px",
      fontSize: "14px",
      lineHeight: "12px",
      fontWeight: 500,
      '& div': {
        cursor: "pointer",
      },
      color: theme.palette.secondary.shade_600,
      '& svg': {
        fill: theme.palette.secondary.shade_600,
      },
    },
    activeBtn: {
      color: theme.palette.primary.main,
      '& svg': {
        fill: theme.palette.primary.main,
      }
    },
    thumbsUpWrapper: {
      flexGrow: ({ is_desktop }) => !is_desktop && 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "4px",
      '& svg': {
        width: "16px",
      },
    },
    disabledBtn: {
      cursor: 'not-allowed !important',
    },
    replyAvatar: {
      width: "37px",
      height: "37px",
      fontSize: "13px",
    },
    addReplySection: {
      display: "flex",
      alignItems: "center",
      margin: "15px 0 0 0",
      gap: ({ is_desktop }) => (is_desktop ? "12px" : "8px"),
    },
    newReplyInput: {
      flex: 1,
    },
    iconButton: {
      padding: 0,
      "&:hover": {
        cursor: "pointer",
      },
    },
    loadingWrapper: {
      display: "flex",
      justifyContent: "center",
      padding: "24px",
      '& img': {
        width: "48px",
      },
    },
    postLoadingWrapper: {
      display: "flex",
      justifyContent: "center",
      '& img': {
        width: "24px",
      },
    },
    accordionRoot: {
      boxShadow: "none",
      "&:before": {
        content: "none !important",
      },
    },
    expandedAccordion: {
      margin: "0 !important",
    },
    accordionSummary: {
      minHeight: "19px !important",
      height: "19px",
      justifyContent: "flex-end",
      flexDirection: "row-reverse",
      width: "150px",
    },
    accordionSummaryContent: {
      margin: "0 !important",
      minHeight: "19px !important",
      flexGrow: 0,
      fontSize: "14px",
      fontWeight: 500,
      color: ({ showReplies }) =>
        showReplies
          ? theme.palette.primary.main
          : theme.palette.secondary.shade_600,
    },
    accordionExpandIcon: {
      padding: 0,
      marginRight: "8px !important",
      filter: ({ showReplies }) =>
        showReplies &&
        "invert(23%) sepia(35%) saturate(4522%) hue-rotate(238deg) brightness(86%) contrast(91%)",
    },
    accordionCollapsedContent: {
      border: "none",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
