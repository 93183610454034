import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bottomNavigation: {
        zIndex: 1
    },
    svgSelected: {
        filter: 'brightness(0) saturate(100%) invert(29%) sepia(46%) saturate(2348%) hue-rotate(215deg) brightness(86%) contrast(86%)'
    }
}),
    { name: 'CreatorTool' }
);

export default useStyles;
