import { api, dataProvider } from "data";
import { HTTP_STATUS_SUCCESS_CODES, apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";

export const fetchFeatureOnboardingFlag = (featureKey) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.onboarding_flag.get}/${featureKey}`,
        apiMethods.GET
      )
      .then((response) => {
        // - in case of empty response, we still need to resolve this call
        // - if this is not done, following things break:
        // - in src/ui/pages/whatsappBroadcast/Components/WhatsappBroadcastModalWrapper/
        // WhatsappBroadcastModalWrapper.jsx, if the api is not resolved, the white_label_introduced and white_label_success_introduced flag is not set and the Onboarding/Introduction or Integration success modal is not shown to creators
        // - other places where `fetchFeatureOnboardingFlag` function is used might break... but have not been checked explicitly
        if (!response) {
          resolve(response);
          return;
        }
        const { status, data = {} } = response || {};
        if (status === HTTP_STATUS_SUCCESS_CODES.HTTP_STATUS_NO_CONTENT)
          resolve(data[featureKey]);
        if (!isRequestSuccessful(status))
          throw new Error("Failure fetching feature onboarding flag");
        resolve(data[featureKey]);
      })
      .catch(reject);
  });

export const updateFeatureOnboardingFlag = (featureKey, value) =>
  dataProvider.custom_request(
    `${api.onboarding_flag.update}`,
    apiMethods.POST,
    {
      flag: featureKey,
      value,
    }
  );
