import { styled } from "@material-ui/core/styles";

/*
  some loader or empty screen might require you use this
  this would make it handy to style them without declaring style for each div
  or using inline style.

 <SkeletonDiv
  background="#EDEDED"
  width="60px"
  height="14px"
  borderRadius="10px"
  marginTop="8px"
/>
*/

const SkeletonDiv = styled("div")({
  borderRadius: ({ borderRadius }) => borderRadius || "8px",
  background: ({ background }) => background || "#E2E2E2",
  width: ({ width }) => width,
  height: ({ height }) => height,
  marginTop: ({ marginTop }) => marginTop,
});

export default SkeletonDiv;
