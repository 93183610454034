import React, { useCallback, useEffect, useState, useRef } from "react";
import { Avatar, Input, Accordion } from "@my-scoot/component-library-legacy";
import IconButton from "@material-ui/core/IconButton";
import classnames from "classnames";

import useStyles from "./Replies.styles";
import ThumbUpAltRoundedIcon from "@material-ui/icons/ThumbUpAltRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { ReactComponent as PlayArrowIcon } from "assets/vectors/playArrow.svg";
import BookingLoader from "assets/images/bookingLoader.gif";

import Reply from "../Reply";

import { postLikeOnCommentInRecordedContent } from "schedule-v2/RecordedContent/RecordedContent.helpers";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const Replies = ({
  commentId,
  replies_count,
  showAddReply,
  setShowAddReply,
  showReplies,
  setShowReplies,
  loggedInUserName,
  postReply,
  getReplies,
  deleteReply,
  additionParams,
  isOnRecordedContent,
  likes_count,
  is_comment_liked,
  onOpenLikesUI,
}) => {
  const [newReplyText, setNewReplyText] = useState("");
  const [postingReply, setPostingReply] = useState(false);
  const [replies, setReplies] = useState({
    items: [],
    count: replies_count,
  });
  const [likesState, setLikesState] = useState({
    is_comment_liked,
    likes_count,
  });
  const [loadingReplies, setLoadingReplies] = useState(false);
  const inputRef = useRef(null);
  const is_desktop = useDesktopMediaQuery();

  useEffect(() => {
    if (replies_count !== replies.count) fetchReplies();
  }, [replies_count, replies]);

  const classes = useStyles({
    is_desktop,
    showAddReply,
    showReplies,
  });

  const onCloseAddReply = useCallback(() => {
    setShowAddReply(false);
    setNewReplyText("");
  }, []);

  const fetchReplies = () => {
    if (!commentId || loadingReplies) return;

    setLoadingReplies(true);
    getReplies(commentId)
      .then(({ total, results }) => {
        setReplies({
          items: results,
          count: total,
        });
        if (total === 0) setShowReplies(false);
      })
      .catch((err) => console.log(`Error while fetching replies: ${err}`))
      .finally(() => setLoadingReplies(false));
  };

  const onPostReply = () => {
    if (!newReplyText || !commentId) return;
    setPostingReply(true);
    postReply(commentId, newReplyText, additionParams)
      .then(() => {
        setNewReplyText("");
        fetchReplies();
        setShowReplies(true);
      })
      .catch((err) =>
        console.log(`Error while posting reply on comment: ${err}`)
      )
      .finally(() => setPostingReply(false));
  };

  const onPostLike = () => {
    if (!commentId) return;
    postLikeOnCommentInRecordedContent(commentId)
      .then(() => {
        setLikesState((prevState) => ({
          is_comment_liked: true,
          likes_count: prevState.likes_count + 1,
        }));
      })
      .catch((err) => {
        if (err.message === "Comment disliked.") {
          setLikesState((prevState) => ({
            is_comment_liked: false,
            likes_count: prevState.likes_count - 1,
          }));
        }
        console.log(`Error while posting like: ${err.message}`);
      });
  };

  const onDeleteReply = (replyId) => {
    if (!replyId) return;
    deleteReply(replyId)
      .then(fetchReplies)
      .catch((err) => console.log(`Err while deleting reply: ${err}`));
  };

  const actions = [
    {
      label: "Delete",
      onClick: onDeleteReply,
    },
  ];

  const handleAddReply = () => setShowAddReply(true);

  useEffect(() => {
    if (showReplies) fetchReplies();
  }, [showReplies]);

  useEffect(() => {
    if (showAddReply && inputRef.current) inputRef.current.focus();
  }, [showAddReply]);

  return (
    <div className={classes.repliesContainer}>
      <div className={classes.repliesHeader}>
        {isOnRecordedContent && (
          <div
            onClick={onPostLike}
            className={likesState.is_comment_liked && classes.activeBtn}
          >
            Like
          </div>
        )}
        <div
          onClick={handleAddReply}
          className={showAddReply && classes.activeBtn}
        >
          Reply
        </div>
        {isOnRecordedContent && (
          <div
            className={classnames(
              classes.thumbsUpWrapper,
              likesState.is_comment_liked && classes.activeBtn,
              !likesState.likes_count && classes.disabledBtn
            )}
            onClick={() => {
              if (likesState.likes_count) onOpenLikesUI(commentId);
            }}
          >
            <span>{likesState.likes_count}</span>
            <ThumbUpAltRoundedIcon />
          </div>
        )}
      </div>

      {!!replies.count && (
        <Accordion
          accordionSummaryContent={<span>{replies.count} Replies</span>}
          expandIconPosition="start"
          classes={{
            root: classes.accordionRoot,
            expanded: classes.expandedAccordion,
          }}
          AccordionSummaryProps={{
            classes: {
              root: classes.accordionSummary,
              expandIcon: classes.accordionExpandIcon,
              content: classes.accordionSummaryContent,
              expanded: classes.accordionSummaryContent,
            },
          }}
          collapsedContentClassName={classes.accordionCollapsedContent}
          expanded={showReplies}
          onChange={(e, val) => setShowReplies(val)}
        >
          {loadingReplies ? (
            <div className={classes.loadingWrapper}>
              <img src={BookingLoader} alt="loading..." />
            </div>
          ) : (
            replies.items.map((currReply) => (
              <Reply
                key={currReply.id || currReply.uuid}
                isOnRecordedContent={isOnRecordedContent}
                {...currReply}
                actions={actions}
              />
            ))
          )}
        </Accordion>
      )}

      {showAddReply && (
        <div className={classes.addReplySection}>
          <Avatar
            label={loggedInUserName}
            colorIndex={0}
            className={classes.replyAvatar}
          />
          <Input
            placeholder="Write Your reply here"
            inputRef={inputRef}
            value={newReplyText}
            onChange={({ target: { value } }) => setNewReplyText(value)}
            wrapperClassName={classes.newReplyInput}
            fullWidth
            iconPosition="end"
            icon={
              <IconButton
                onClick={onCloseAddReply}
                classes={{ root: classes.iconButton }}
              >
                <CloseRoundedIcon />
              </IconButton>
            }
          />
          {postingReply ? (
            <div className={classes.postLoadingWrapper}>
              <img src={BookingLoader} alt="loading..." />
            </div>
          ) : (
            <IconButton
              disabled={!newReplyText || postingReply}
              classes={{ root: classes.iconButton }}
              onClick={onPostReply}
            >
              <PlayArrowIcon />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

export default Replies;
