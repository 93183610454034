import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: "relative",
      width: ({ width }) => width,
    },
    relative: {
      height: "40px",
    },
    inputRoot: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      minWidth: "100px",
      width: ({ width }) => width,
      overflow: "hidden",
      "&::-webkit-calendar-picker-indicator": {
        background: "none",
        display: "none",
        "-webkit-appearance": "none",
      },
    },
    title: {
      color: theme?.palette?.secondary?.main,
      fontSize: "14px",
      marginBottom: "8.75px",
      lineHeight: "17px",
      fontWeight: 500,
    },
    label: {
      position: "absolute",
      color: ({ showPlaceholder }) => (showPlaceholder ? "#8A8A8A" : "#272522"),
      left: "1.5px",
      top: "1.5px",
      bottom: "1.5px",
      right: "1.5px",
      height: "37px",
      fontSize: "14px",
      background: theme.palette.basic.white,
      display: "flex",
      alignItems: "center",
      marginBottom: 0,
      justifyContent: "space-between",
      padding: "18.5px 14px",
      borderRadius: "5px",
    },
    input: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddedInput: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    clockIcon: {
      width: "20px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
