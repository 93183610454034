import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles((theme) => ({
  desktop_whatsapp_phone_field: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: theme?.palette?.primary?.main,
    "&:hover": {
      color: theme?.palette?.primary?.main,
    },
  },
  desktop_whatsapp_icon: {
    width: "16px",
    maxHeight: "16px",
  },
  international_status: {
    color: ({ is_international }) =>
      is_international ? "#1f890e" : theme?.palette?.basic?.black,
  },
  action: {
    color: theme?.palette?.primary?.main,
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  switchRoot: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  tooltipHeader: {
    minWidth: "111px",
  },
}));
