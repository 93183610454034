import React from "react";
import { Chip } from "@my-scoot/component-library-legacy";
import { useStyles } from "./ListingTypeChip.styles";
import { schedule_type_names } from "constants/schedule";

export const ListingTypeChip = ({ listingTypeId }) => {
  const classes = useStyles();

  return (
    <Chip
      chipClassName={classes[`listing_type__${listingTypeId}`]}
      label={schedule_type_names[listingTypeId]}
    />
  );
};
