import { useState, useEffect, useCallback } from "react";

import local_storage_keys from "constants/LocalStorage.constants";
import {
  fetchFeatureOnboardingFlag,
  updateFeatureOnboardingFlag,
} from "./FeatureOnboarding.helpers";

const useFeatureOnboardingChecker = ({
  featureKey,
  successCallback = () => {},
}) => {
  const [isOnboarded, setIsOnboarded] = useState(true);
  const [flagLoading, setFlagLoading] = useState(false);

  // flag value before flag update call
  const [isOnboardedPrevVal, setIsOnboardedPrevVal] = useState(true);

  useEffect(() => {
    if (!featureKey) return;

    const allFeaturesData = JSON.parse(
      localStorage.getItem(local_storage_keys.IS_FEATURE_ONBOARDED)
    );
    if (allFeaturesData && allFeaturesData[featureKey]) return;

    setFlagLoading(true);

    fetchFeatureOnboardingFlag(featureKey)
      .then((response) => {
        const updatedData = {
          ...(allFeaturesData || {}),
          [featureKey]: response,
        };
        localStorage.setItem(
          local_storage_keys.IS_FEATURE_ONBOARDED,
          JSON.stringify(updatedData)
        );
        setIsOnboarded(response);
        setIsOnboardedPrevVal(response);
        successCallback(response);
      })
      .catch((err) => {
        console.log("Error in Feature Onboard call", err);
      })
      .finally(() => setFlagLoading(false));
  }, [featureKey]);

  const handleFeatureOnboardChange = useCallback(
    (value) => {
      const allFeaturesData = JSON.parse(
        localStorage.getItem(local_storage_keys.IS_FEATURE_ONBOARDED)
      );
      updateFeatureOnboardingFlag(featureKey, value)
        .then(({ status }) => {
          if (status !== 200)
            throw new Error("Error while updating feature onboarding flag");

          const updatedData = {
            ...(allFeaturesData || {}),
            [featureKey]: value,
          };
          localStorage.setItem(
            local_storage_keys.IS_FEATURE_ONBOARDED,
            JSON.stringify(updatedData)
          );
          setIsOnboarded(value);
        })
        .catch((err) => {
          console.log("Error in Feature Onboard Change Hook", err);
        });
    },
    [featureKey]
  );

  return {
    isOnboarded,
    isOnboardedPrevVal,
    flagLoading,
    handleFeatureOnboardChange,
  };
};

export default useFeatureOnboardingChecker;
