import React, { useState, useMemo } from "react";
import moment from "moment";
import { Avatar } from "@my-scoot/component-library-legacy";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

import useStyles from "./Comment.styles";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";

import Replies from "./components/Replies";

import { is_desktop } from "utils/Utils";
import {
  BLOG_FIELDS,
  CourseCommentType,
  RECORDED_CONTENT_FIELDS,
} from "./Comment.utils";

const Comment = ({
  comment = {},
  getCommentActions,
  loggedInUserName,
  postReply,
  getReplies,
  deleteReply,
  isOnRecordedContent,
  additionParams,
  onOpenLikesUI,
}) => {
  const { created_at, replies_count, is_comment_liked, likes_count } = comment;
  const currConstant = useMemo(() => {
    return isOnRecordedContent ? RECORDED_CONTENT_FIELDS : BLOG_FIELDS;
  }, [isOnRecordedContent]);
  const commenter = comment[currConstant.COMMENTOR];
  const content = comment[currConstant.COMMENT];
  const commentId = comment[currConstant.ID];
  const commentType = comment[currConstant.type];
  const isHtmlComment = commentType === CourseCommentType.HTML;

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const isActionPopoverOpen = Boolean(popoverAnchorEl);
  const [showAddReply, setShowAddReply] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

  const classes = useStyles({
    is_desktop,
    showReplies: showReplies || showAddReply,
  });

  const onOpenActionPopover = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const onCloseActionPopover = () => setPopoverAnchorEl(null);

  return (
    <div className={classes.container}>
      <Avatar
        label={commenter}
        colorIndex={0}
        className={classes.commentAvatar}
      />
      <div className={classes.rightSection}>
        <div className={classes.detailsWrapper}>
          <div className={classes.commentHeader}>
            <div className="d-flex align-items-center">
              <span className={classes.commenterName}>{commenter}</span>
              <span className={classes.commentTime}>
                {moment(created_at).fromNow()}
              </span>
            </div>
            <IconButton
              classes={{
                root: classes.iconButton,
              }}
              onClick={onOpenActionPopover}
            >
              <MoreVertRoundedIcon />
            </IconButton>
            <Popover
              anchorEl={popoverAnchorEl}
              open={isActionPopoverOpen}
              onClose={onCloseActionPopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              {getCommentActions(comment).map(({ label, onClick }) => (
                <div
                  key={label}
                  onClick={() => {
                    onClick();
                    onCloseActionPopover();
                  }}
                  className={classes.actionItem}
                >
                  {label}
                </div>
              ))}
            </Popover>
          </div>
          {isHtmlComment ? (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            <div className={classes.commentContent}>{content}</div>
          )}
        </div>
        <Replies
          commentId={commentId}
          replies_count={replies_count}
          loggedInUserName={loggedInUserName}
          showAddReply={showAddReply}
          setShowAddReply={setShowAddReply}
          showReplies={showReplies}
          setShowReplies={setShowReplies}
          postReply={postReply}
          getReplies={getReplies}
          deleteReply={deleteReply}
          additionParams={additionParams}
          isOnRecordedContent={isOnRecordedContent}
          is_comment_liked={is_comment_liked}
          likes_count={likes_count}
          onOpenLikesUI={onOpenLikesUI}
        />
      </div>
    </div>
  );
};

export default Comment;
