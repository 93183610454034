import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Modal,
  MobileModal,
  Button,
  Card,
  Radio,
} from "@my-scoot/component-library-legacy";
import useStyles from "./BroadcastModal.styles";
import { useNotifications, useToggleState } from "utils/hooks";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyImage from "../ExlyImage";

const ExlyBroadcastModal = ({
  open,
  onClose,
  modal_props = {},
  mobile_modal_props = {},
  broadcastActions = [],
}) => {
  const isDesktop = useMediaQuery("(min-device-width: 767px)");
  const classes = useStyles({ isDesktop });
  const [selected_action, set_selected_action] = useState(
    broadcastActions.length ? broadcastActions[0] : null
  );

  const modal_common_content = broadcastActions
    .filter((action) => !action.hidden)
    .map((action) => (
      <Card
        key={`${action.title}`}
        CardClassName={classes.card}
        onClick={() => set_selected_action(action)}
      >
        <Radio checked={selected_action === action} />
        <div className={classes.cardWrapper}>
          <div>
            {action.img && (
              <ExlyImage src={action.img} width={30} height={30} />
            )}
          </div>
          <div className={classes.card_content}>
            <div className={classes.card_title}>{action.title}</div>
            <div className={classes.card_description}>{action.description}</div>
          </div>
        </div>
      </Card>
    ));

  return isDesktop ? (
    <Modal
      open={open}
      onClose={onClose}
      onSecondaryBtnClick={onClose}
      primaryBtnText="Next"
      onPrimaryBtnClick={selected_action?.onClick}
      primaryBtnFullWidth={true}
      showSecondaryBtn={false}
      primaryBtnProps={{ disabled: !selected_action }}
      modalPaperClassName={classes.modal_paper}
      {...modal_props}
    >
      <div className={classes.desktop_content}>{modal_common_content}</div>
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={onClose}
      primaryAction={{
        label: "Next",
        onClick: selected_action?.onClick,
        btnProps: { disabled: !selected_action },
      }}
      {...mobile_modal_props}
    >
      <div className={classes.mobile_content}>{modal_common_content}</div>
    </MobileModal>
  );
};

export const BroadcastModal = withComponentLibraryTheme(ExlyBroadcastModal);

const BroadcastModalWrapper = ({ cta_props = {}, ...rest_props }) => {
  const [open, onOpen, onClose] = useToggleState(false);
  const { notify } = useNotifications();

  return (
    <>
      <Button
        onClick={() => {
          if (
            cta_props.enableCheckSelectedIdsEmpty &&
            cta_props.isEmptySelectedIds
          )
            return notify("Please select customers first", "error");

          onOpen();
        }}
        {...cta_props}
      />
      {open && <BroadcastModal open={open} onClose={onClose} {...rest_props} />}
    </>
  );
};

export default withComponentLibraryTheme(BroadcastModalWrapper);
