import React, { useState, useMemo } from "react";
import { Avatar } from "@my-scoot/component-library-legacy";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

import useStyles from "./Reply.styles";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";

import { is_desktop } from "utils/Utils";
import { BLOG_FIELDS, RECORDED_CONTENT_FIELDS } from "../../Comment.utils";

const Reply = ({ isOnRecordedContent, actions = [], ...rest }) => {
  const currConstant = useMemo(() => {
    return isOnRecordedContent ? RECORDED_CONTENT_FIELDS : BLOG_FIELDS;
  }, [isOnRecordedContent]);
  const commenter = rest[currConstant.COMMENTOR];
  const content = rest[currConstant.COMMENT];
  const id = rest[currConstant.ID];
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const isActionPopoverOpen = Boolean(popoverAnchorEl);

  const classes = useStyles({ is_desktop });

  const onOpenActionPopover = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const onCloseActionPopover = () => setPopoverAnchorEl(null);

  return (
    <div className={classes.container}>
      <Avatar
        label={commenter}
        colorIndex={0}
        className={classes.replyAvatar}
      />
      <div className={classes.rightSection}>
        <div>{content}</div>
        <IconButton
          classes={{
            root: classes.iconButton,
          }}
          onClick={onOpenActionPopover}
        >
          <MoreVertRoundedIcon />
        </IconButton>
        <Popover
          anchorEl={popoverAnchorEl}
          open={isActionPopoverOpen}
          onClose={onCloseActionPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: classes.popoverPaper,
          }}
        >
          {actions.map(({ label, onClick }) => (
            <div
              key={label}
              onClick={() => {
                onClick(id);
                onCloseActionPopover();
              }}
              className={classes.actionItem}
            >
              {label}
            </div>
          ))}
        </Popover>
      </div>
    </div>
  );
};

export default Reply;
