import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Modal, MobileModal } from "@my-scoot/component-library-legacy";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useStyles from './PreviewModal.styles';

const PreviewModal = ({ open, onClose, preview_image }) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const ExlyModal = isDesktop ? Modal : MobileModal;

  return (
    <ExlyModal
      title="Preview"
      header="Preview"
      open={open}
      onClose={onClose}
      customFooter={<></>}
      modalPaperClassName={classes.desktop_modal_paper}
      paperClassName={classes.mobile_modal_paper}
    >
      <div className={classes.wrapper}>
        <img
          src={
            isDesktop
              ? require(`assets/images/${preview_image}`)
              : require(`assets/images/mobile_preview/${preview_image}`)
          }
          alt="preview_image"
          className={classes.preview_image}
        />
      </div>
    </ExlyModal>
  );
};

export default withComponentLibraryTheme(PreviewModal);
