import React from "react";
import classnames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import style from "./ActionDrawer.module.css";

function HorizontalDotsIconButton({ onClick, classes = {} }) {
  return (
    <IconButton
      onClick={onClick}
      className={classnames(style.iconButton, classes.iconButton)}
    >
      <MoreHorizRoundedIcon className={classnames(style.icon, classes.icon)} />
    </IconButton>
  );
}

export default HorizontalDotsIconButton;
