import React from "react";

import ExlyModal from "../ExlyModal";

import WarningIcon from "@material-ui/icons/WarningRounded";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

import classnames from "classnames";
import { useStyles } from "./index.styles";

const ExlyDeleteModal = ({
  title,
  description,
  onDelete,
  deleteBtnText = "Delete",
  showWarningIcon = false,
  loading,
  ...restProps
}) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles();

  return (
    <ExlyModal
      modal_props={{ modalPaperClassName: classes.modalPaperClassName }}
      title={
        <div
          className={classnames(
            isDesktop ? classes.desktopModalHeader : classes.mobileModalHeader
          )}
        >
          <WarningIcon className={classes.warningIcon} />
          <span>{title}</span>
        </div>
      }
      primaryBtnText={deleteBtnText}
      onPrimaryBtnClick={onDelete}
      primaryBtnProps={{
        className: classes.primaryBtnClassName,
        loading,
        disabled: loading,
      }}
      {...restProps}
    >
      <div
        className={classnames(
          isDesktop
            ? classes.desktopWarningWrapper
            : classes.mobileWarningWrapper
        )}
      >
        {showWarningIcon && <WarningIcon className={classes.warningIcon} />}

        <span>{description}</span>
      </div>
    </ExlyModal>
  );
};

export default ExlyDeleteModal;
