import React from "react";
import { Card, Button } from "@my-scoot/component-library-legacy";

import useStyles from "./ExlyTable.styles";

const ExlyTableFooter = ({
  primarybtn,
  primaryClick,
  hidePrimarybtn = false,
  primaryBtnProps,
  secondaryBtn,
  secondaryClick,
  hideSecondaryBtn = false,
  data,
  isFullLayout,
  secondaryBtnColor = "subtlePrimary",
  primaryBtnJSX,
  secondaryBtnJSX,
}) => {
  const classes = useStyles({ isFullLayout });
  const primaryBtnPresent = primarybtn || primaryBtnJSX;
  const secondaryBtnPresent = secondaryBtn || secondaryBtnJSX;
  const oneButtonLayout = !(primaryBtnPresent && secondaryBtnPresent);
  const showSecondaryBtn = secondaryBtnPresent && !hideSecondaryBtn;
  const showPrimaryBtn = primaryBtnPresent && !hidePrimarybtn;
  // Need to show following button if hide =false & thier props are present

  return (
    <Card className={classes.footer}>
      {showSecondaryBtn &&
        (secondaryBtnJSX || (
          <Button
            onClick={() => secondaryClick(data)}
            color={secondaryBtnColor}
            size="small"
            //@DEV TODO this inline style should be converted into classes later, unable to test this for now as this is being used in N number of tables.
            style={{
              height: "34px",
              width: oneButtonLayout ? "calc(100vw - 32px)" : "inherit",
            }}
          >
            {secondaryBtn}
          </Button>
        ))}
      {showPrimaryBtn &&
        (primaryBtnJSX || (
          <Button
            onClick={() => primaryClick(data)}
            color="primary"
            size="small"
            //@DEV TODO this inline style should be converted into classes later, unable to test this for now as this is being used in N number of tables.
            style={{
              height: "34px",
              width: oneButtonLayout ? "calc(100vw - 32px)" : "inherit",
            }}
            {...primaryBtnProps}
          >
            {primarybtn}
          </Button>
        ))}
    </Card>
  );
};

export default ExlyTableFooter;
