import { makeStyles } from "@material-ui/core/styles";
import { schedule_types_ids } from "constants/schedule";

const schedule_type_chips_styles = {
  [schedule_types_ids.one_on_one_appointment]: {
    backgroundColor: "#F7D6D4",
    color: "#DF645D",
  },
  [schedule_types_ids.one_on_one_class]: {
    backgroundColor: "#CCFFEE",
    color: "#003322",
  },
  [schedule_types_ids.group_class]: {
    backgroundColor: "#CCFFEE",
    color: "#00C652",
  },
  [schedule_types_ids.workshop]: {
    backgroundColor: "rgba(0, 139, 248, 0.1)",
    color: "#026FC6",
  },
  [schedule_types_ids.no_schedule]: {
    backgroundColor: "#DCD9F2",
    color: "#40329A",
  },
  [schedule_types_ids.content]: {
    backgroundColor: "#E7E6E4",
    color: "#0E0D0C",
  },
  [schedule_types_ids.merchandise]: {
    backgroundColor: "#FFEECC",
    color: "#CC8800",
  },
  [schedule_types_ids.rolling_class]: {
    backgroundColor: "#CCE0FF",
    color: "#002966",
  },
  [schedule_types_ids.telegram]: {},
  [schedule_types_ids.webinar]: {},
  [schedule_types_ids.whatsapp]: {},
  [schedule_types_ids.branded_community]: {},
};

export const useStyles = makeStyles(
  () => ({
    [`listing_type__${schedule_types_ids.one_on_one_appointment}`]: {
      background: `${
        schedule_type_chips_styles[schedule_types_ids.one_on_one_appointment]
          .backgroundColor
      } !important`,
      color: `${
        schedule_type_chips_styles[schedule_types_ids.one_on_one_appointment]
          .color
      } !important`,
    },
    [`listing_type__${schedule_types_ids.one_on_one_class}`]: {
      background: `${
        schedule_type_chips_styles[schedule_types_ids.one_on_one_class]
          .backgroundColor
      } !important`,
      color: `${
        schedule_type_chips_styles[schedule_types_ids.one_on_one_class].color
      } !important`,
    },
    [`listing_type__${schedule_types_ids.group_class}`]: {
      background: `${
        schedule_type_chips_styles[schedule_types_ids.group_class]
          .backgroundColor
      } !important`,
      color: `${
        schedule_type_chips_styles[schedule_types_ids.group_class].color
      } !important`,
    },
    [`listing_type__${schedule_types_ids.workshop}`]: {
      background: `${
        schedule_type_chips_styles[schedule_types_ids.workshop].backgroundColor
      } !important`,
      color: `${
        schedule_type_chips_styles[schedule_types_ids.workshop].color
      } !important`,
    },
    [`listing_type__${schedule_types_ids.no_schedule}`]: {
      background: `${
        schedule_type_chips_styles[schedule_types_ids.no_schedule]
          .backgroundColor
      } !important`,
      color: `${
        schedule_type_chips_styles[schedule_types_ids.no_schedule].color
      } !important`,
    },
    [`listing_type__${schedule_types_ids.content}`]: {
      background: `${
        schedule_type_chips_styles[schedule_types_ids.content].backgroundColor
      } !important`,
      color: `${
        schedule_type_chips_styles[schedule_types_ids.content].color
      } !important`,
    },
    [`listing_type__${schedule_types_ids.merchandise}`]: {
      background: `${
        schedule_type_chips_styles[schedule_types_ids.merchandise]
          .backgroundColor
      } !important`,
      color: `${
        schedule_type_chips_styles[schedule_types_ids.merchandise].color
      } !important`,
    },
    [`listing_type__${schedule_types_ids.rolling_class}`]: {
      background: `${
        schedule_type_chips_styles[schedule_types_ids.rolling_class]
          .backgroundColor
      } !important`,
      color: `${
        schedule_type_chips_styles[schedule_types_ids.rolling_class].color
      } !important`,
    },
  }),
  { name: "ListingTypeChip" }
);
