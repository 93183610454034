import React, { useMemo, useRef } from "react";
import {
  Input,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import {
  convert_24_hrs_to_12_hrs_format,
  convert_12_hrs_to_24_hrs_format,
} from "../TimePicker.utils";
import useStyles from "./NativeTimePicker.styles";
import { is_empty } from "utils/validations";

const NativeTimePicker = ({
  value,
  onChange,
  placeholder,
  uniqueId,
  native_width,
  label,
  padded = false,
  disabled = false,
  ...restProps
}) => {
  const inputRef = useRef(null);

  const displayValue = value || placeholder;

  const classes = useStyles({
    showHelperText: restProps.helperText,
    showPlaceholder: !value,
    width: native_width,
  });

  const handleChange = (e) => {
    const value_24_hrs_format = e.target.value;
    const value_12_hrs_format =
      convert_24_hrs_to_12_hrs_format(value_24_hrs_format);
    onChange(value_12_hrs_format);
  };

  const value_24_hrs_format = useMemo(() => {
    return convert_12_hrs_to_24_hrs_format(value);
  }, [value]);

  return (
    <ThemeProvider theme={creatorToolTheme}>
      {label && <div className={classes.title}>{label}</div>}
      <div className={classes.wrapper}>
        {is_empty(value_24_hrs_format) ? (
          <label className={classes.label} htmlFor={uniqueId}>
            <span>{displayValue}</span>
            <QueryBuilderIcon className={classes.clockIcon} />
          </label>
        ) : null}

        <Input
          inputRef={inputRef}
          type="time"
          size="small"
          value={value_24_hrs_format}
          onChange={handleChange}
          inputClassName={classes.inputRoot}
          classes={{ input: padded ? classes.paddedInput : classes.input }}
          id={uniqueId}
          disabled={disabled}
          {...restProps}
        />
      </div>
    </ThemeProvider>
  );
};

export default NativeTimePicker;
