import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    exlyTableRoot: {
      "& .exly-creator-tool--MuiToolbar-root, & .exly--MuiToolbar-root": {
        display: "none",
      },
    },
    simpleList: {
      "& > *": {
        width: ({
          isDesktop,
          gridWidth = { desktop: "33%", mobile: "100%" },
        }) => (isDesktop ? gridWidth.desktop : gridWidth.mobile),
        padding: ({ isDesktop }) =>
          isDesktop ? "0 18px 12px 0" : "0 0 12px 0",
      },
    },
    listContentRoot: {
      width: 0,
      borderRadius: "8px",
      boxShadow: ({ noBoxShadow }) =>
        noBoxShadow ? "" : "0px 3px 0px rgba(0, 0, 0, 0.14)",
      margin: ({ margin }) => margin,
    },
    bulkActions: {
      margin: 0,
    },
    emptyStateWrapper: {
      margin: ({ margin }) => margin,
    },
    filteredDivWrapper: {
      marginTop: "4px",
    },
    actionsWrapper: {
      display: "none",
    },
    newFilterWrapper: {
      display: "flex",
      color: "#493AB1",
      alignItems: "center",
      gap: "4px",
      opacity: ({ isFilterDisabled }) => (isFilterDisabled ? "0.5" : "1"),
      "&:hover": {
        cursor: "pointer",
      },
    },
    filterMobileFilter: {
      opacity: ({ isFilterDisabled }) => (isFilterDisabled ? "0.5" : "1"),
    },
    MenuOptions: {
      background: "#ffffff",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "5px",
      color: "#272522",
      padding: "16px 13px 16px 12px",
    },
    menuOptionsEmpty: {
      background: "#ffffff",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "5px",
      color: "#272522",
      padding: "16px 13px 16px 12px",
      height: ({ isDesktop }) => (isDesktop ? "" : "100vh"),
      width: ({ isDesktop }) => (isDesktop ? "" : "100vw"),
    },
    emptyStatePara: {
      maxWidth: ({ isDesktop }) => (isDesktop ? "222px" : "400px"),
      paddingRight: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: ({ isDesktop }) => (isDesktop ? "12px" : "16px"),
      lineHeight: "18px",
      /* identical to box height, or 136% */

      letterSpacing: "0.02em",

      color: " rgba(39, 37, 34, 0.6)",
    },
    header: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: ({ isDesktop }) => (isDesktop ? "14px" : "20px"),
      lineHeight: "19px",
      /* identical to box height, or 136% */

      letterSpacing: "0.02em",

      color: "rgba(39, 37, 34, 0.85)",
    },
    leftDiv: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    addFilter: {
      color: "#493AB1",
      display: "flex",
      alignItems: "center",
      gap: "9.67px",
      fontWeight: "500",
      "&:hover": {
        cursor: "pointer",
      },
    },
    filterHeader: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "15px",
      /* identical to box height, or 136% */

      letterSpacing: "0.04em",

      color: " rgba(0, 0, 0, 0.3)",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "21px",
      paddingBottom: "9px",
      display: "flex",
      alignItems: "center",
      gap: "4px",

      "&>img": {
        marginBottom: "2px",
      },
    },
    filterFooterWrapper: {
      width: ({ isDesktop }) => (isDesktop ? "100%" : "95%"),
      margin: "auto",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "17px",
      /* identical to box height, or 136% */
      padding: "8px",
      textAlign: "center",
      color: " #FFFFFF",
      background: "#493AB1",
      border: "1.5px solid #493AB1",
      borderRadius: ({ isDesktop }) => (isDesktop ? "0px" : "5px"),

      "&:hover": {
        cursor: "pointer",
      },
    },
    bold: {
      fontWeight: "600",
    },
    checkBoxWrapperMobile: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
      fontSize: "14px",
      fontWeight: "500",
    },
    numberOfLeads: {
      opacity: "0.8",
    },
    blueText: {
      fontWeight: "600",
      color: "#493AB1",
    },
    lightBlueText: {
      fontWeight: "600",
      color: "#0052CC",
    },
    greenText: {
      fontWeight: "600",
      color: "#00A570",
    },
    cardDeleteWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    footerWrapperDiv: {
      position: "fixed",
      bottom: "0px",
      width: "100%",
      padding: "16px",
      borderTop: "2px solid #E7E6E4",
    },
    customFiltersMobileWrapper: {
      position: "absolute",
      top: "0px",
      right: "5px",
      background: "white",
      zIndex: "2",
      borderLeft: "1px solid #E7E6E4",
      height: "60px",
      padding: "9px 9px",

      "&:hover": {
        cursor: "pointer",
      },
    },
    customFiltersMobileWrapperLeads: {
      background: "white",
      zIndex: "2",
      borderLeft: "2px solid #E7E6E4",
      borderBottom: "2px solid #E7E6E4",
      padding: "11px 9px",
      width: "70px",

      "&:hover": {
        cursor: "pointer",
      },
    },
    filterTabsWrapper: {
      borderTop: "1px solid #E7E6E4",
      display: "flex",
      alignItems: "flex-start",
    },
    filterNumbers: {
      background: "#493AB1",
      border: "2px solid #FFFFFF",
      color: "#FFFFFF",
      borderRadius: "50%",
      width: "18px",
      height: "18px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "15px",
      textAlign: "center",
      position: "absolute",
      top: "4px",
      left: "40px",
    },
    filterNumbersDesktop: {
      background: "#493AB1",
      border: "2px solid #FFFFFF",
      color: "#FFFFFF",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "15px",
      textAlign: "center",
    },
    filterCard: {
      padding: "16px",
      borderRadius: "5px",
      width: "92%",

      "&:hover": {
        cursor: "pointer",
      },
    },
    filterBodyWrapper: {
      background: "#FAFAFA",
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      height: ({ isDesktop }) => (isDesktop ? "" : "80vh"),
    },
    fullFilterWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
    },

    filtersPopoverWrapper: {
      width: ({ isDesktop }) => (isDesktop ? "450px" : "100vw"),
    },
    flexGrow: {
      flexGrow: 1,
    },
    exportBoxWrapper: {
      background: ({ isDesktop }) => !isDesktop && "#F8F9FB",
      padding: ({ isDesktop }) => !isDesktop && "2px 16px",
      width: ({ isDesktop }) => !isDesktop && "100vw",
      justifyContent: ({ isDesktop }) => !isDesktop && "space-between",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      marginLeft: ({ isDesktop }) => !isDesktop && "-15px", //giving negative margin to negate the padding given to mobile layout
    },
    sendImage: {
      marginRight: "8px",
    },
    listWrapper: {
      width: "100%",
      border: "none",
      padding: "0",
      "&>.MuiToolbar-root": {
        display: "none",
      },
      overflowX: ({ isDesktop }) => !isDesktop && "hidden",
    },
    desktopPaginationClassName: {
      float: "right",
      marginRight: "16px",
      marginBottom: "23px",
      marginTop: "12px",
    },
    desktopTableWrapper: {
      borderRadius: "8px",
      overflowY: "auto",
      maxHeight: "70vh",
    },
    filterBox: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      marginBottom: ({ hideFilters, hasTabs }) =>
        hideFilters && !hasTabs ? "" : "16px",
      // position: ({isDesktop}) => isDesktop ? '' : 'sticky',
      // top: ({ hasTabs }) => hasTabs ? '39.8px' : "-16px",
      // backgroundColor: 'white',
      // zIndex: 1,
      // padding: '16px 0'
      // width: ({isDesktop}) => isDesktop ? '100%' :'calc(100vw - 32px)'
    },
    chip: {
      height: "auto !important",
      maxWidth: "100%",
    },
    chipLabel: {
      whiteSpace: "normal",
    },
    dropdownFilter: {
      display: "block",
      width: "100%",
    },

    dropdownFilterInput: {
      width: "100%",
      border: "none",
      "&.Mui-focused": {
        border: "none",
      },
    },
    inputFilter: {
      width: "100%",
      "& fieldset": {
        border: "none",
        "&:hover": {
          border: "none",
        },
        "&:focus": {
          border: "none",
        },
      },
    },
    noFilterPlaceholder: {
      fontSize: "14px",
      padding: "0 14px",
    },
    filterPlayground: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      border: `1.5px solid ${theme?.palette.secondary.shade_200}`,
      borderRadius: "5px",
      overflow: "hidden",
      maxWidth: ({ isDesktop }) => (isDesktop ? "390px" : "inherit"),
      minWidth: ({ isDesktop }) => (isDesktop ? "390px" : "inherit"),
    },
    filterInputWrapper: {
      flex: 1,
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      height: " 100%",
      borderRight: `1.5px solid ${theme?.palette.secondary.shade_200}`,
      "&>div:first-child": {
        width: "100%",
      },
    },
    searchByDropdown: {
      width: "126px",
      border: "none",
      height: "32px",
      "&.Mui-focused": {
        border: "none",
      },
    },
    submitButton: {
      width: "126px",
      border: "none",
      height: "32px",
      borderRadius: 0,
    },
    selectedFiltersWrapper: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      gap: "12px",
      maxWidth: "100%",
    },
    exportBtn: {
      cursor: "pointer",
      color: theme?.palette.primary.main,
      paddingLeft: "10px",
      marginLeft: "3px",
      fontSize: "35px",
    },
    exportText: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme?.palette.primary.main,
      cursor: "pointer",
    },
    form: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: ({ showSelectAllcheckBox, isDesktop }) =>
        !showSelectAllcheckBox ? "row" : isDesktop ? "row" : "column-reverse",
      gap: ({ isDesktop }) => !isDesktop && "16px",
    },
    messageExportDivWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    selectBoxdiv: {
      display: "flex",
      justifyContent: "space-between",
    },
    footer: {
      borderRadius: "0px",
      borderTop: `1.5px solid ${theme?.palette?.secondary.shade_100}`,
      padding: "8px 14px",
      display: "flex",
      justifyContent: "space-between",
      position: "fixed",
      bottom: ({ isFullLayout }) => (isFullLayout ? "80px" : "0px"),
      left: 0,
      width: "100%",
      height: "52px",
    },
    noBorder: {
      border: "none",
    },
    paginationWrapper: {
      width: "100%",
      background: "white",
      // zIndex: 100,
      position: "fixed",
      bottom: ({ hasMobileFooter, isFullLayout }) =>
        hasMobileFooter ? (isFullLayout ? "132px" : "52px") : "0px",
      left: "0",
    },
    subTabWrapper: {
      marginTop: "16px",
      marginBottom: ({ isDesktop }) => !isDesktop && "16px",
    },
    datePickerPopperClassName: {
      zIndex: 5,
    },
    chipsContainer: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    clearFilters: {
      fontSize: "16px",
      color: theme?.palette?.basic?.danger_red,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    nav_wrap_customer_list: {
      position: "sticky",
      float: "top",
      top: "0px",
      padding: "10px 0px",
      borderBottom: "2px solid #E7E6E4",
      color: "black",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      "&>svg": {
        color: "black !important",
      },
    },
    mobileFiltersWrapper: {
      position: "fixed",
      top: "0px",
      left: "0px",
      height: "100vh",
      background: "white",
      zIndex: "6000",
    },
    select: {
      padding: ({ selectSize: size }) =>
        size === "thin"
          ? `6.15px 24px 6.15px 12px`
          : `9.165px 24px 9.165px 12px`,
      lineHeight: "normal",
    },
  }),
  { name: "ExlyTableWrapper" }
);

export default useStyles;
