export const EXLY_TABLE_EMPTY_STATES_VERSIONS = {
  V1: 1,

  /**
   * Changelog:
   * 1. Added support for custom image path.
   * 2. Added support for videos.
   * 3. Added "Learn More" CTA support:
   *    3.1. Opens custom href on click.
   * 4. Added shimmer loading state.
   */
  V2: 2,
};
