import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';
import TabLayout from './TabLayout';

const LayoutWrapper = (props) => {
    const isDesktop = useMediaQuery("(min-width: 767px)");
    const { children, tabConfig, ...rest } = props;

    const Layout = isDesktop ? DesktopLayout : MobileLayout;
    
    return (
        <Layout {...rest}>
            {props.tabConfig ? <TabLayout {...props}  /> :children}
        </Layout>
    );
}

export default LayoutWrapper;
