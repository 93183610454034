import { makeStyles, useMediaQuery } from "@material-ui/core";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import React from "react";
import classnames from "classnames";

/**
 * The Following component can be used to provide uniform styling to our tabs component which has been used throughout the creator tool
 */

const useStyles = makeStyles((theme) => ({
  tabsWrapperRoot: {
    marginBottom: "12px",
    width: ({ isSmallMobile }) => isSmallMobile && "320px",
  },
  tabsWrapperScroller: {
    borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
    margin: "-2px -16px 0 -16px",
  },
}));

const ExlyTabsWrapper = ({ children, styles }) => {
  const isDesktop = useDesktopMediaQuery();
  const isSmallMobile = useMediaQuery("(max-device-width: 366px)");

  const classes = useStyles({ isDesktop, isSmallMobile });

  return (
    <div className={classnames(classes.tabsWrapperRoot, styles?.tabsWrapper)}>
      {children}
      {!isDesktop && (
        <div className={classnames(classes.tabsWrapperScroller)} />
      )}
    </div>
  );
};

export default ExlyTabsWrapper;
