import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import React from "react";
import { Checkbox } from "@my-scoot/component-library-legacy";
import styles from "./checkboxGroup.module.css";
import classnames from "classnames";

/**
 *
 * @param {{
 * options: {id: number, label: string, value: any, checked: boolean}[],
 * error: string,
 * helperText: string,
 * onChange: Function,
 * classes: { label: string, group: string }
 * }} options
 * @returns
 */

const CheckboxGroup = ({
  value = [],
  options,
  error,
  helperText,
  onChange,
  label,
  labelClassName,
  classes = {},
  required = true,
  ...restProps
}) => {
  const handleChange = (event) => {
    const {
      target: { name },
    } = event;
    const index = value ? value.findIndex((id) => id === name) : -1;
    if (index !== -1) {
      const newValue = [...value];
      newValue.splice(index, 1);
      if (onChange) onChange(newValue);
    } else {
      if (onChange) onChange([...value, name]);
    }
  };

  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      {...restProps}
    >
      {label && (
        <FormLabel className={labelClassName} component="legend">
          {label}
        </FormLabel>
      )}
      <FormGroup className={classnames(styles.group, classes.group)}>
        {options?.map((option) => (
          <FormControlLabel
            className={classnames(styles.label, classes?.label)}
            key={option.id}
            name={option.id}
            disabled={option.disabled}
            control={
              <Checkbox
                wrapperClassName={option.disabled && styles.disabled}
                checked={value && value.includes(option.id)}
                onChange={handleChange}
                size="large"
                error={error || option.error}
                color={option.color}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {(error || helperText) && (
        <FormHelperText
          className={classnames(styles.helperText, error && styles.error)}
        >
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckboxGroup;
