import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import EmptyListIcon from "assets/vectors/infoModal/search.svg";

const useStyles = makeStyles(
  () => ({
    wrapper: {
      color: "rgba(0, 0, 0, 0.85)",
      width: "240px",
      margin: ({ isDesktop }) =>
        isDesktop ? "52px 0 40px 128px" : "52px auto 0 auto",
    },
    title: {
      margin: "24px 0 20px 0",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: 700,
    },
    description: {
      fontSize: "16px",
      lineHeight: "19px",
    },
  }),
  { name: "CreatorTool" }
);

const ExlyFilteredEmptyState = ({
  primaryText = "No results found",
  secondaryText = "Try different keywords",
  customEmptyState,
}) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });

  if (customEmptyState) return customEmptyState;

  return (
    <div className={classes.wrapper}>
      <img src={EmptyListIcon} alt="empty-icon" />
      <div className={classes.title}>{primaryText}</div>
      <div className={classes.description}>{secondaryText}</div>
    </div>
  );
};

export default ExlyFilteredEmptyState;
