import React from "react";
import classnames from "classnames";
import { ReactComponent as TipsAndUpdatesIcon } from "assets/vectors/tips_and_updates.svg";

export const TipsAndUpdates = ({ className, tip, iconSize }) => {
  return (
    <div className={classnames(className, "d-flex align-items-center")}>
      <TipsAndUpdatesIcon className="mr-2" width={iconSize} height={iconSize} />
      {tip}
    </div>
  );
};
