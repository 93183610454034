import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { BottomNavigation } from "@my-scoot/component-library-legacy";

import useStyles from "./ExlyMobileNavigation.styles";
import { getTabConfig } from "./ExlyMobileNav.config";

const ExlyMobileNavigation = (props) => {
  const [value, setValue] = useState(0);
  const [permissions, setPermissions] = useState(null);
  const [tabs, setTabs] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();

  useEffect(() => {
    const selectedTab = tabs.find((tab) => tab.path === location?.pathname);
    if (selectedTab && Object.keys(selectedTab).length !== -1) {
      setValue(tabs.indexOf(selectedTab));
    }
  }, [location.pathname, tabs]);

  useEffect(() => {
    let menuPermissions = sessionStorage.getItem("menu_permissions");

    if (menuPermissions) {
      let permissions = JSON.parse(menuPermissions);
      setPermissions(permissions.hidden_items);
    }
  }, []);

  useEffect(() => {
    const protectedTabs = getTabConfig(permissions);
    setTabs(protectedTabs);
  }, [permissions]);

  const onChange = (e, newValue) => {
    history.push(tabs[newValue].path);
    setValue(newValue);
  };

  return (
    <BottomNavigation
      items={tabs}
      fixed_bottom
      value={value}
      onChange={onChange}
      className={classes.bottomNavigation}
      {...props}
    />
  );
};

export default ExlyMobileNavigation;
