import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    modalPaperClassName: {
      minWidth: "532px",
    },
    warningIcon: {
      color: theme?.palette?.basic?.danger_red,
    },
    desktopModalHeader: {
      display: "flex",
      alignItems: "center",
      gap: "10.47px",
      height: "22px",
    },
    mobileModalHeader: {
      display: "flex",
      alignItems: "center",
      gap: "12.94px",
      height: "22px",
    },
    desktopWarningWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "9.81px",
      padding: "24px 0px",
      "& svg": {
        fontSize: "97.38px",
        color: theme?.palette?.basic?.danger_red,
      },
    },
    mobileWarningWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "1.81px",
      padding: "16px 0px",
      fontFamily: theme?.typography?.fontFamily,
      "& svg": {
        fontSize: "97.38px",
        color: theme?.palette?.basic?.danger_red,
      },
    },
    primaryBtnClassName: {
      backgroundColor: `${theme?.palette?.basic?.danger_red} !important`,
    },
  }),
  { name: "CreatorTool" }
);
