export const CREDITS_EXPIRY_STATUS = {
  active: 1,
  expired: 2,
  warning: 3,
};

export const FEATURE_KEYS = {
  whatsapp_blast: {
    key: "whatsapp_blast",
  },
  email_marketing: {
    key: "email_marketing",
  },
  cash_upload_gmv: {
    key: "cash_upload_gmv",
  },
};
