import React from "react";
import classnames from "classnames";
import {
  creatorToolTheme,
  ThemeProvider,
  MobilePagination,
  DesktopPagination,
} from "@my-scoot/component-library-legacy";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStyles from "../ExlyTable.styles";
import { useListContext } from "react-admin";
import styles from "../ExlyTableStyles.module.css";

const ExlyTablePagination = ({
  hasMobileFooter,
  isFullLayout,
  classes: propClasses = {},
  ...props
}) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ hasMobileFooter, isFullLayout });
  const { total } = useListContext();

  // Previous condition -> if (!total || total < perPage) return null;
  // now we need to show pagination even when total < perPage (after crm-phase2)
  if (!total) return null;

  return (
    <ThemeProvider theme={creatorToolTheme}>
      {isDesktop ? (
        <DesktopPagination
          {...props}
          paginationClassName={classes.desktopPaginationClassName}
          recordLabelCustomClassName={styles.recordLabelCustomStyle}
        />
      ) : (
        <MobilePagination
          {...props}
          wrapperClassName={classnames(
            classes.paginationWrapper,
            propClasses.mobileWrapper
          )}
        />
      )}
    </ThemeProvider>
  );
};

export default ExlyTablePagination;
