import React from "react";
import styles from "./Style.module.css";
import classnames from "classnames";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

export const MobileFooter = ({
  children,
  className,
  accountForBottomNavbar,
}) => {
  const isDesktop = useDesktopMediaQuery();
  return !isDesktop ? (
    <div
      className={classnames(
        styles.root,
        className,
        accountForBottomNavbar && styles.showAboveBottomNavbar
      )}
    >
      {children}
    </div>
  ) : null;
};
