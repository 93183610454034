import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    desktop_modal_paper: {
      maxWidth: "90%",
      width: "90%",
    },
    wrapper: {
      padding: ({ isDesktop }) => (isDesktop ? "24px" : "16px"),
    },
    preview_image: {
      width: "100%",  
      height: "100%",
      objectFit: "cover",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
