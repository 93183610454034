import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import classnames from "classnames";
import { IconButton } from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

const useStyles = makeStyles(
  (theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "24px",
    },
    title: {
      color: theme?.palette?.secondary?.main,
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    backBtn: {
      padding: "0",
    },
    description: {
      color: theme?.palette?.secondary?.main,
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19px",
      marginBottom: "32px",
      display: "inline-block",
    },
  }),
  { name: "CreatorTool" }
);

const DesktopLayout = ({
  children,
  title,
  secondaryComponent,
  paddingDesktop,
  description,
  descriptionClassName,
  showBackCta,
  headerClassName,
}) => {
  const classes = useStyles({ padding: paddingDesktop });
  const showSecondaryComponent = !!secondaryComponent;

  return (
    <Box>
      {(showSecondaryComponent || title) && (
        <Box className={classnames(classes.header, headerClassName)}>
          <Typography className={classes.title} component="h1">
            {showBackCta && (
              <IconButton
                className={classes.backBtn}
                onClick={() => window.history.go(-1)}
              >
                <ArrowBackRoundedIcon />
              </IconButton>
            )}
            {title}
          </Typography>
          {showSecondaryComponent && secondaryComponent}
        </Box>
      )}
      {!!description && (
        <Typography
          className={classnames(classes.description, descriptionClassName)}
          component="span"
        >
          {description}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default DesktopLayout;
