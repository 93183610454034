import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      padding: ({ isDesktop }) => (isDesktop ? "20px 24px" : "0px"),
    },
    page_title: {
      color: theme?.palette?.secondary?.main,
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 700,
    },
    content_wrapper: {
      padding: ({ isDesktop }) =>
        isDesktop ? "68px 0 0 100px" : "34px 8px 0 8px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "rgba(0, 0, 0, 0.85)",
    },
    content_title: ({ isDesktop }) =>
      isDesktop
        ? {
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 700,
            color: "rgba(0, 0, 0, 0.85)",
            margin: "24.39px 0 20px 0",
          }
        : {
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 700,
            color: "rgba(0, 0, 0, 0.85)",
            margin: "24px 0 16px 0",
          },
    cta_footer: {
      display: "flex",
      marginTop: "24px",
      gap: "11px",
    },
    primary_cta: {
      padding: ({ isDesktop }) => (isDesktop ? "0 24px" : "0 13px"),
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
