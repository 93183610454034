import React, { useCallback } from "react";
import { Field } from "react-final-form";

import ExlyDropdown from "../../../form/ExlyDropdown";

import useStyles from "../ExlyTable.styles";

const ExlyTableFilterDropdown = ({
  placeholder,
  options,
  source,
  filterValues,
  setFilters,
  selectSize = "thin",
}) => {
  const classes = useStyles({ selectSize });

  const onChange = useCallback(
    (value, name) => {
      if (Array.isArray(value)) {
        // if more than 1 param needed to added on selecting a dropdown value, ref: src/ui/pages/ManageBrandedCommunity/ManageCommunityHome/pages/ManageMembers/ManageMembers.data.js
        const inputFilters = value.reduce(
          (filtersMap, { name, value }) => ({
            ...filtersMap,
            [name]: value,
          }),
          {}
        );
        setFilters({
          ...filterValues,
          ...inputFilters,
        });
      } else
        setFilters({
          ...filterValues,
          [name]: value,
        });
    },
    [filterValues]
  );

  const optionsToRender = options?.filter((option) => !option.hidden) || [];

  return (
    <Field
      component={ExlyDropdown}
      selectClassName={classes.select}
      name={source}
      options={optionsToRender}
      fullWidth
      placeholder={placeholder}
      onChange={onChange}
      formControlClassName={classes.dropdownFilter}
      inputBaseRootClassName={classes.dropdownFilterInput}
    />
  );
};

export default ExlyTableFilterDropdown;
