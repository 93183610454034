import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as EmptyIcon } from "assets/vectors/readingMan.svg";

const useStyles = makeStyles(
  () => ({
    container: {
      padding: "32px 0 0 24px",
    },
    content: {
      marginTop: "27px",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 700,
    },
  }),
  { name: "CreatorTool" }
);

const EmptyComments = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <EmptyIcon />
      <div className={classes.content}>No Comments Received Yet</div>
    </div>
  );
};

export default EmptyComments;
