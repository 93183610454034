import React from "react";
import {
  MobilePagination,
  DesktopPagination,
} from "@my-scoot/component-library-legacy";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

export const Pagination = ({
  page,
  perPage = 50,
  onPageChange,
  total,
  desktopPaginationProps,
  mobilePaginationProps,
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (!total || total <= perPage) return null;

  return isDesktop ? (
    <DesktopPagination
      page={page}
      setPage={onPageChange}
      total={total}
      perPage={perPage}
      {...desktopPaginationProps}
    />
  ) : (
    <MobilePagination
      page={page}
      total={total}
      setPage={onPageChange}
      perPage={perPage}
      {...mobilePaginationProps}
    />
  );
};
