import { getFileFormat, getFileNameWOFormat } from "utils/fileUtils";

/**
 *
 * @param file Video file object to upload
 * @param customFilePath custom starting file path to segregate different content video files w/o ending fwd slash
 * @returns S3 relative path for the Uploaded file (on the S3 Instance)
 */
export const generateCustomPathForFile = (
  file: File,
  customFilePath: string
) => {
  const fileName = getFileNameWOFormat(file.name || "");
  const fileFormat = getFileFormat(file.name || "");
  return `${customFilePath}/${Date.now()}/${fileName}_${Date.now()}_.${fileFormat}`;
};
