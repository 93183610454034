import React from "react";
import { Select } from "@my-scoot/component-library-legacy";
import classnames from "classnames";

import { is_empty } from "utils/validations";
import useStyles from "./styles";

import {
  EmptyOfferings,
  Offering,
  OfferingDesctiption,
  OfferingFooter,
} from "./HelperComponents";

const OfferingSelect = ({
  loading,
  allowCreateOffering = false,
  showStatus,
  lastElementRef,
  classes: propClasses,
  EmptyOfferings: EmptyOfferingsProp = EmptyOfferings,
  emptyText,
  mobileModalTitle,
  emptyMobileModalTitle = "No live offerings",
  hideEmptyCreateOfferingBtn = false,
  ...restProps
}) => {
  const {
    multiple,
    placeholder,
    options,
    route,
    selectedOfferingType,
    selectedOfferingTypeIsRequired,
    onCreateOfferingClick,
  } = restProps;
  const classes = useStyles();

  return (
    <Select
      renderSelectedValue={
        multiple
          ? null
          : ({ selectedValue, selectedOption }) =>
              selectedValue ? (
                <OfferingDesctiption {...selectedOption} isCondensed />
              ) : (
                placeholder
              )
      }
      headerComponent={
        is_empty(options) &&
        !loading && (
          <EmptyOfferingsProp
            emptyText={emptyText}
            route={route}
            selectedOfferingType={selectedOfferingType}
            selectedOfferingTypeIsRequired={selectedOfferingTypeIsRequired}
            onCreateOfferingClick={onCreateOfferingClick}
            hideEmptyCreateOfferingBtn={hideEmptyCreateOfferingBtn}
          />
        )
      }
      renderOption={(renderProps) => (
        <Offering
          key={renderProps?.option?.uuid}
          showCheckbox={multiple}
          showStatus={showStatus}
          lastElementRef={lastElementRef}
          {...renderProps}
        />
      )}
      mobileModalTitle={
        is_empty(options) && !loading ? emptyMobileModalTitle : mobileModalTitle
      }
      footerComponent={
        <OfferingFooter
          isLoading={loading}
          allowCreateOffering={allowCreateOffering && !is_empty(options)}
          route={route}
          selectedOfferingType={selectedOfferingType}
          selectedOfferingTypeIsRequired={selectedOfferingTypeIsRequired}
          onCreateOfferingClick={onCreateOfferingClick}
        />
      }
      classes={{
        popoverPaper: classes.scroll,
        contentWrap: classnames(
          classes.scroll,
          allowCreateOffering && classes.contentMaxHeight
        ),
        ...propClasses,
      }}
      {...restProps}
      showSelectAll={false}
    />
  );
};

export default OfferingSelect;
