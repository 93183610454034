import { isInteger, is_empty } from "utils/validations";
import { CREDITS_EXPIRY_STATUS } from "./ubp.constants";
import React from "react";
import moduleStyle from "./ubp.module.css";

export const getWarningMsg = (featureCreditsData) => {
  if (isInteger(featureCreditsData?.consumption_percent)) {
    if (
      featureCreditsData?.consumption_percent ===
      featureCreditsData?.error_percent
    ) {
      return (
        <span className={`ml-2`}>
          Usage limit reached. &nbsp;
          <span className={`${moduleStyle.cta}`}>Buy More limit</span>
        </span>
      );
    }
    return (
      <span className={`ml-2`}>
        {`${featureCreditsData?.consumption_percent}% ${
          featureCreditsData?.name
            ? `of ${featureCreditsData?.name?.toLowerCase()}`
            : ""
        } is used.`}
        &nbsp;
        <span className={`${moduleStyle.cta}`}>Buy More</span>
      </span>
    );
  }
};

export const getCreditExpiryStatus = (creditsData) => {
  if (is_empty(creditsData)) return;

  const {
    warning_percent,
    consumption_percent,
    error_percent,
    credits_expiry_status,
  } = creditsData;

  if (!is_empty(credits_expiry_status)) {
    return credits_expiry_status;
  }

  if (
    !isInteger(warning_percent) ||
    !isInteger(consumption_percent) ||
    !isInteger(error_percent) ||
    error_percent === consumption_percent
  ) {
    return CREDITS_EXPIRY_STATUS.expired;
  }

  if (warning_percent <= consumption_percent) {
    return CREDITS_EXPIRY_STATUS.warning;
  } else if (warning_percent >= consumption_percent) {
    return CREDITS_EXPIRY_STATUS.active;
  }

  return CREDITS_EXPIRY_STATUS.expired;
};
