import EmptyListing from "common/Components/EmptyListing/EmptyListing";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import React from "react";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "./constants/ExlyTableEmptyState.constants";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";

const ExlyTableEmptyState = ({
  empty_list_icon,
  emptyListIconClassName,
  alt,
  children,
  showPrimaryCta = true,
  primary_cta,
  customEmptyState,
  emptyStateProps,
  /**
   * `{...restProps}` is added to support older usage of `emptyStateProps`.
   * Earlier we used to spread `emptyStateProps` directly in `ExlyTable`, which was wrong.
   * Update in existing places if you see `emptyStateProps` being spread directly into `ExlyTable`
   * Prefer using `emptyStateProps` and EXLY_TABLE_EMPTY_STATES_VERSIONS.V2
   */
  ...restProps
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (customEmptyState) return customEmptyState;

  const { version } = emptyStateProps ?? {};

  if (version === EXLY_TABLE_EMPTY_STATES_VERSIONS.V2)
    return <ExlyEmptyState {...emptyStateProps} />;

  return (
    <EmptyListing
      logo={
        <img
          className={emptyListIconClassName}
          src={empty_list_icon}
          alt={alt}
          style={
            isDesktop
              ? {}
              : {
                  width: "212.01px",
                  height: "165px",
                }
          }
        />
      }
      primary_cta={showPrimaryCta && primary_cta}
      {...restProps}
    >
      {children}
    </EmptyListing>
  );
};

export default ExlyTableEmptyState;
