import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import classnames from "classnames";
import { MobilePopover } from "@my-scoot/component-library-legacy";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { IExlyPopover } from "./interfaces/IExlyPopover";

const useStyles = makeStyles(
  () => ({
    wrapper: {
      display: "inline-block",
    },
    popoverPaper: {
      boxShadow: "0px 4px 4px rgba(14, 13, 12, 0.16)",
      borderRadius: "5px",
    },
    "@media (max-width:780px)": {},
  }),
  { name: "CreatorTool" }
);

/**
 * openPopover needs event object
 *
 * TODO: split PopoverComponent from AnchorComponent.
 * It will be needed in the scenario PopoverComponent should not be rendered (eg: multiple times in a list)
 * together with the AnchorComponent.
 */
const ExlyPopover: FC<IExlyPopover> = ({
  PopoverComponent = () => <></>,
  AnchorComponent = () => <></>,
  wrapperClassName,
  onClose = () => {},
  popoverProps = {},
  forceDesktopDesign = true,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const [anchor, setAnchor] = useState<Element | null>(null);
  const isActionPopoverOpen = Boolean(anchor);
  const classes = useStyles();

  const openPopover = (event: React.SyntheticEvent) => {
    setAnchor(event.currentTarget);
  };

  const closePopover = (e?: React.SyntheticEvent) => {
    setAnchor(null);
    onClose(e);
  };

  const PopoverWrapperComponent =
    isDesktop || forceDesktopDesign ? Popover : MobilePopover;

  const designSpecificProps =
    isDesktop || forceDesktopDesign
      ? {
          classes: {
            paper: classes.popoverPaper,
          },
        }
      : {};

  return (
    <div className={classnames(classes.wrapper, wrapperClassName)}>
      {AnchorComponent ? <AnchorComponent openPopover={openPopover} /> : null}
      <PopoverWrapperComponent
        anchorEl={anchor}
        open={isActionPopoverOpen}
        onClose={closePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        {...designSpecificProps}
        {...popoverProps}
      >
        <PopoverComponent closePopover={closePopover} />
      </PopoverWrapperComponent>
    </div>
  );
};

export default ExlyPopover;
