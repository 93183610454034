import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Box } from "@my-scoot/component-library-legacy";

import useStyles from "../ExlyTable.styles";
import ExlyTabsWrapper from "common/Components/ExlyTabsWrapper";

const formatFilterObject = (
  tabFilterConfig,
  tabsDisabled,
  hideAllTab = false
) => {
  const _tabFilterConfig = tabFilterConfig.map((i) => {
    return {
      ...i,
      value: i.value || i.label,
      disabled: tabsDisabled,
    };
  });

  return hideAllTab
    ? _tabFilterConfig
    : [{ label: "All", value: "all" }, ..._tabFilterConfig];
};

const ExlyTabFilters = ({
  setFilters,
  filterValues,
  tabFilterConfig,
  setValue,
  value,
  tabsDisabled,
  wrapperClasses,
  hideAllTab, // hideAllTab flag is used when we don't want to show the all tabs in tab filters
}) => {
  const classes = useStyles();
  const [subTabValue, setSubTabValue] = useState();
  const [subTab, setSubTab] = useState(null);
  const _tabFilterConfig = formatFilterObject(
    tabFilterConfig,
    tabsDisabled,
    hideAllTab
  );

  useEffect(() => {
    //set Pre-selected on load.
    _tabFilterConfig.forEach((filter) => {
      if (
        filter.source &&
        Object.keys(filterValues).indexOf(filter.source) !== -1 &&
        filterValues[filter.source] === filter.value
      ) {
        //level one filter.
        setValue(filter.value);
      } else if (filter.subTabs) {
        //level two filters
        filter.subTabs.forEach((subFilter) => {
          if (
            Object.keys(filterValues).indexOf(subFilter.source) !== -1 &&
            filterValues[subFilter.source] === subFilter.value
          ) {
            setSubTab(filter.subTabs);
            setValue(filter.value);
            setSubTabValue(subFilter.value);
          }
        });
      }
    });
  }, [filterValues]);

  const applyFilter = useCallback(
    ({ source, filterValue }) => setFilters({ [source]: filterValue }),
    [setFilters, subTabValue, value]
  );

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    const selectedFilter = _tabFilterConfig.find(
      (filter) => filter.value === newValue
    );

    if (selectedFilter?.value === "all") {
      //Remove Tab Filters.
      setFilters({});
      setSubTabValue();
      setSubTab(null);
      return;
    }
    if (!selectedFilter?.subTabs) {
      //No Sub Filters
      applyFilter(selectedFilter);
      setSubTabValue();
      setSubTab(null);
      return;
    }
    //Sub Filters
    setFilters({});
    setSubTab(selectedFilter.subTabs);
  }, []);

  const handleSubTabChange = useCallback(
    (event, newValue) => {
      setSubTabValue(newValue);
      const selectedFilter = subTab?.find(
        (filter) => filter.value === newValue
      );
      applyFilter(selectedFilter);
    },
    [subTab]
  );

  return (
    <Box className={wrapperClasses ? wrapperClasses.tabsWrapper : ""}>
      <ExlyTabsWrapper>
        <Tabs
          key="level-one"
          value={value}
          tabVariant="filled_primary"
          onChange={handleChange}
          tabs={_tabFilterConfig}
        />
      </ExlyTabsWrapper>
      {!!subTab && (
        <Box className={classes.subTabWrapper}>
          <Tabs
            key="level-two"
            value={subTabValue}
            tabVariant="filled_secondary"
            onChange={handleSubTabChange}
            tabs={subTab}
          />
        </Box>
      )}
    </Box>
  );
};

export default ExlyTabFilters;
