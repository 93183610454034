import {
  DEFAULT_TIME_FORMAT_12,
  DEFAULT_TIME_FORMAT_24,
} from "constants/dateTime";
import moment from "moment";

export const convert_24_hrs_to_12_hrs_format = (value) => {
  if (!value) return value;
  return moment(value, [DEFAULT_TIME_FORMAT_24]).format(DEFAULT_TIME_FORMAT_12);
};

export const convert_12_hrs_to_24_hrs_format = (value) => {
  if (!value) return value;
  return moment(value, [DEFAULT_TIME_FORMAT_12]).format(DEFAULT_TIME_FORMAT_24);
};
