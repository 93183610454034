import React from "react";
import { Checkbox, Button, Chip } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import { ReactComponent as EmptyListingIcon } from "assets/vectors/empty-offering.svg";

import useStyles from "./styles";
import constants from "constants/constants";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { CircularProgress } from "@material-ui/core";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { customCodeLabels } from "ui/pages/CustomCodes/Codes.config";
import { AddCircleOutline } from "@material-ui/icons";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { isInteger } from "utils/validations";
import { notification_color_keys, useNotifications } from "utils/hooks";

export const OfferingLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loaderWrapper}>
      <CircularProgress />
    </div>
  );
};

export const EmptyOfferings = ({
  emptyText = customCodeLabels.emptyOfferingText,
  route,
  selectedOfferingType,
  selectedOfferingTypeIsRequired,
  hideEmptyCreateOfferingBtn,
  onCreateOfferingClick = () => {},
}) => {
  const classes = useStyles();
  const { notify } = useNotifications();

  return (
    <div className={classes.emptyOfferingWrap}>
      <EmptyListingIcon />
      <div className={classes.emptyContent}>{emptyText}</div>
      {!hideEmptyCreateOfferingBtn && (
        <Button
          onClick={() => {
            window.sessionStorage.setItem(
              SessionStorageConstants.ROUTE_COMING_FROM,
              route
            );
            if (selectedOfferingTypeIsRequired) {
              if (isInteger(selectedOfferingType)) {
                onCreateOfferingClick();
              } else {
                notify("Offering Type Missing", notification_color_keys.error);
                return;
              }
            }
            window.location.href = `${window.location.origin}/#/${
              app_route_ids[app_route_keys.create_offering]
            }`; // always need to redirect to create an offering page with no layout to reduce dropoff
          }}
          size="medium"
          fullwidth={true}
        >
          Create an Offering
        </Button>
      )}
    </div>
  );
};

export const SelectAll = ({ handleToggle, isAllSelected, isIndeterminate }) => {
  const classes = useStyles();
  return (
    <div onClick={handleToggle("ALL")} className={classes.selectAll}>
      <Checkbox
        size="large"
        checked={isAllSelected}
        indeterminate={isIndeterminate}
      />
      <span className={classes.font_14}>
        {isAllSelected
          ? "Remove all from the list"
          : "Select all from the list"}
      </span>
    </div>
  );
};

export const SelectedChips = ({ selectedOfferings, handleToggle }) => {
  const classes = useStyles();
  return (
    <div className={classes.chips}>
      {selectedOfferings.map((offering) => (
        <Chip
          key={offering.uuid}
          label={offering.title}
          chipIcon={
            <ClearRoundedIcon
              onMouseDown={(event) => event.stopPropagation()}
            />
          }
          chipClassName={classes.filterChip}
          onDelete={handleToggle(offering, false)}
        />
      ))}
    </div>
  );
};

const listingStatusMap = {
  0: {
    label: "Draft",
    background: "coral_red",
  },
  1: {
    label: "Live",
    background: "fine_pine",
  },
  2: {
    label: "In Waitlist",
    background: "butterscotch_yellow",
  },
  3: {
    label: "Past",
    background: "butterscotch_yellow",
  },
  5: {
    label: "Deleted",
    background: "danger_red",
  },
};

const getOfferingDescription = ({
  currency,
  type,
  price_per_head,
  price_international,
}) => {
  const _currency = constants.CURRENCY_SYMBOL_MAP[currency] || currency;
  let result = `${_currency} ${price_per_head}`;
  if (price_international)
    result += `, ${constants.usd_currency} ${price_international}`;
  result += ` ${
    (type || type === 0) &&
    type >= 0 &&
    type <= constants.schedule_type.length - 1
      ? "| " + constants.schedule_type[type].name
      : ""
  }`;
  return result;
};

export const OfferingDesctiption = ({
  cover_image,
  title,
  isCondensed,
  showCheckbox,
  isSelected,
  hide_description = false,
  cover_icon = false,
  ...rest
}) => {
  const classes = useStyles();
  const description = getOfferingDescription(rest);

  return (
    <div className={classes.offeringLeftSection}>
      {showCheckbox && (
        <Checkbox checked={isSelected} tabIndex={-1} size="large" />
      )}
      {cover_icon ? (
        <div
          className={classnames(
            classes.cover_icon,
            isCondensed && classes.small_icon
          )}
        >
          {cover_icon}
        </div>
      ) : (
        <img
          src={cover_image}
          className={classnames(
            classes.cover_image,
            isCondensed && classes.small_img
          )}
        />
      )}
      <div>
        <div
          className={classnames(
            classes.font_14,
            !isCondensed && !hide_description && "mb-2"
          )}
        >
          {title}
        </div>
        {!hide_description && (
          <div className={classes.font_12}>{description}</div>
        )}
      </div>
    </div>
  );
};

export const Offering = ({
  option,
  isSelected,
  isLast,
  lastElementRef,
  onChange,
  showStatus,
  showCheckbox,
}) => {
  const classes = useStyles({ isSelected: isSelected && !showCheckbox });
  const { label, background } = listingStatusMap[option.status] || {};

  return (
    <div
      onClick={() => onChange(option.value)}
      className={classes.offering}
      {...(isLast ? { ref: lastElementRef } : {})}
    >
      <OfferingDesctiption
        {...option}
        isSelected={isSelected}
        showCheckbox={showCheckbox}
      />

      {showStatus && label && (
        <div className={classnames(classes.status, classes[background])}>
          {label}
        </div>
      )}
    </div>
  );
};

export const OfferingFooter = ({
  isLoading,
  allowCreateOffering,
  ...restProps
}) => {
  const {
    route,
    selectedOfferingType,
    selectedOfferingTypeIsRequired,
    onCreateOfferingClick = () => {},
  } = restProps;
  return (
    <>
      {isLoading && <OfferingLoader />}
      {allowCreateOffering && (
        <CreateOfferingRedirect
          route={route}
          selectedOfferingType={selectedOfferingType}
          selectedOfferingTypeIsRequired={selectedOfferingTypeIsRequired}
          onCreateOfferingClick={onCreateOfferingClick}
        />
      )}
    </>
  );
};

export const CreateOfferingRedirect = ({
  route,
  selectedOfferingType,
  selectedOfferingTypeIsRequired,
  onCreateOfferingClick,
}) => {
  const classes = useStyles();
  const { notify } = useNotifications();
  return (
    <div className={classes.createOfferingRedirect}>
      <div className={classes.redirectLabel}>Want to add a new offering?</div>
      <Button
        onClick={() => {
          sessionStorage.setItem(
            SessionStorageConstants.ROUTE_COMING_FROM,
            route
          );
          if (selectedOfferingTypeIsRequired) {
            if (isInteger(selectedOfferingType)) {
              onCreateOfferingClick();
            } else {
              notify("Offering Type Missing", notification_color_keys.error);
              return;
            }
          }

          window.location.href = `${window.location.origin}/#/${
            app_route_ids[app_route_keys.create_offering]
          }`;
        }}
        color="secondary"
        variant="outlined"
        size="medium"
        fullwidth={true}
        className={classes.redirectBtn}
      >
        <AddCircleOutline
          fontSize="16px"
          classes={{ root: classes.redirectBtnSvg }}
        />
        Create an Offering
      </Button>
    </div>
  );
};
