import React, { useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from "@my-scoot/component-library-legacy";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const useStyles = makeStyles((theme) => ({
  tabsWrapperRoot: {
    marginBottom: "12px",
  },
  tabsWrapperScroller: {
    borderBottom: ({ showTabsBottomBorder }) =>
      showTabsBottomBorder &&
      `2px solid ${theme?.palette?.secondary?.shade_100}`,
    margin: "-2px -16px 0 -16px",
  },
}));

const TabsWrapper = ({ children, showTabsBottomBorder }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop, showTabsBottomBorder });

  return (
    <div className={classes.tabsWrapperRoot}>
      {children}
      {!isDesktop && <div className={classes.tabsWrapperScroller} />}
    </div>
  );
};

const TabLayout = ({
  children,
  selectedTab,
  tabConfig,
  tabProps,
  showTabsBottomBorder = true,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [selected, setSelected] = useState(
    selectedTab ? selectedTab : tabConfig[0]?.value
  );

  const handleChange = (event, newValue) => {
    const currentSelected = tabConfig.find((tab) => tab.value === newValue);
    setSelected(newValue);
    history.replace(currentSelected?.path);
  };

  return (
    <>
      <TabsWrapper showTabsBottomBorder={showTabsBottomBorder}>
        <Tabs
          className={classes.tabsWrapper}
          tabs={tabConfig}
          value={selected}
          onChange={handleChange}
          tabVariant="outlined"
          {...tabProps}
        />
      </TabsWrapper>
      {children}
    </>
  );
};

export default TabLayout;
