// Third party imports
import React from "react";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Switch } from "@my-scoot/component-library-legacy";
import classnames from "classnames";

// Styles and icons
import useStyles from "./TableFields.styles";
import styles from "./style.module.css";

// Utils and providers
import { is_empty } from "../../../utils/validations";
import { DateConvert, parsePhoneNumber } from "utils/Utils";
import constants from "constants/constants";
import { questions_type_mapping } from "constants/customerQuestions.constants";
import Tooltip from "ui/modules/Tooltip";

export const WhatsAppPhoneField = ({
  record = {},
  source,
  countryCodeKey = "country_code",
}) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles();

  const phone_number = parsePhoneNumber(record, {
    countryCodeKey,
    phoneNumberKey: source,
  });

  if (is_empty(record[source])) return "N/A";

  if (!isDesktop) return phone_number;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={classes.desktop_whatsapp_phone_field}
      href={`https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`}
    >
      {phone_number}
      <img
        alt="whatsapp"
        src={require("../../../assets/images/whatsapp.png")}
        className={classes.desktop_whatsapp_icon}
      />
    </a>
  );
};

export const DateTimeField = ({ record = {}, source }) => {
  if (is_empty(record[source])) return "N/A";
  return typeof record[source] === "number"
    ? moment
        .unix(record[source])
        .format(`${constants.displayDateYearTimeFormat}, hh:mm${"\u00A0"}A`)
    : DateConvert(record, source, "time");
};

export const InternationalStatusField = ({ record = {}, source }) => {
  const is_international = Boolean(record[source]);
  const classes = useStyles({ is_international });
  return (
    <span className={classes.international_status}>
      {is_international ? "Yes" : "No"}
    </span>
  );
};

export const QuestionsTypeField = ({ record = {} }) => {
  return <span>{questions_type_mapping[record?.type]?.label}</span>;
};

export const ActionField = ({
  onClick = () => {},
  label,
  record,
  className,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classnames(classes.action, className)}
      onClick={() => onClick({ record })}
    >
      {label}
    </div>
  );
};

export const SwitchField = ({
  className,
  label,
  labelClassName,
  active,
  onChange,
  record,
  hideSwitch,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.switchRoot, className)}>
      {!hideSwitch && (
        <Switch
          checked={active}
          onChange={(e, checked) => onChange?.(checked, record)}
          color="success"
          {...restProps}
        />
      )}
      <span className={labelClassName}>{label}</span>
    </div>
  );
};

/* OfferingPlanField component shows offering name along with plan name if purchased from plan  */
export const OfferingPlanField = ({ record = {} }) => {
  return (
    <>
      <div>{record.session_name || record.listing_name}</div>
      {record.purchased_plan_name ? (
        <div>
          <span className={styles.offeringPlan}>Plan: </span>
          <span>{record.purchased_plan_name}</span>
        </div>
      ) : null}
    </>
  );
};

export const TooltipHeaderField = ({ label, note, className = "" }) => (
  <div className={classnames(styles.tooltipHeader, className)}>
    {label}
    {note && <Tooltip text={note} />}
  </div>
);
