// had to use makeStyles because backgroundColor of progress bar was not overriding

import { makeStyles } from "@material-ui/core";

export const useExlyProgressStyles = makeStyles({
  progress: {
    background: "none",
    borderRadius: "3px",
    height: ({ progressHeight }) => progressHeight ?? "8px",
    width: "100%",
    "& *": {
      backgroundColor: ({ progressColor }) =>
        progressColor ?? "rgba(73, 58, 177, 1)",
    },
  },
  progressBackground: {
    position: "absolute",
    height: ({ progressHeight }) => progressHeight ?? "8px",
    width: "100%",
    backgroundColor: ({ progressBackgroundColor }) =>
      progressBackgroundColor ?? "#E7E6E4",
    top: "0px",
  },
  stepper: {
    height: ({ progressHeight }) => progressHeight ?? "8px",
  },
});
