import React from "react";
import classes from "./CustomColumnetailsFieldValueMapperStyles.module.css";

const CustomColumnDetailsFieldValueMapper = ({ data, columns, recordSlug }) => {
  return (
    <>
      {columns?.map((item) => {
        return (
          <div className={classes.row} key={item.column_slug}>
            <div className={classes.row_title}>{item.fieldName} :</div>
            <div className={classes.row_value}>
              {item.fieldValueFormatter({
                record: data,
                recordSlug,
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CustomColumnDetailsFieldValueMapper;
