import React from "react";
import { useExlyProgressStyles } from "./exlyProgress.styles";
import { Progress } from "@my-scoot/component-library-legacy";

export const ExlyProgress = ({
  progressColor,
  progressHeight,
  stepsCount,
  activeStep,
  progressBackgroundColor,
  variant = "progress-bar",
}) => {
  const classes = useExlyProgressStyles({
    progressColor,
    progressHeight,
    progressBackgroundColor,
  });

  return (
    <Progress
      classes={{
        progress: classes.progress,
        progressBackground: classes.progressBackground,
      }}
      stepperClassName={classes.stepper}
      variant={variant}
      stepsCount={stepsCount}
      activeStep={activeStep}
    />
  );
};
