import React from "react";
import cx from "classnames";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import useStyles from "./ErrorHelperText.styles";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

const ErrorHelperText = ({ text, classes: propClasses = {} }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.helperTextWrapper, propClasses.wrapper)}>
      <WarningRoundedIcon
        preserveAspectRatio="none"
        viewBox="3 4 19.06 17.01"
        className={cx(classes.errorIcon, propClasses.errorIcon)}
      />
      <span className={cx(classes.helperText)}>{text}</span>
    </div>
  );
};

export default withComponentLibraryTheme(ErrorHelperText);
