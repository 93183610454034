import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: ({ width }) => width,
      minWidth: "147.59px",
    },
    label: {
      fontSize: "14px",
      lineHeight: "17px",
      color: "#272522",
      fontWeight: 500,
      marginBottom: "8px",
    },
    wrapper: {
      width: ({ width }) => width,
      height: "40px",
      border: ({ error }) =>
        error ? "1.5px solid #BF2600" : "1.5px solid #CFCDC9",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      padding: "0 7.59px 0 10px",
      minWidth: "147.59px",
    },
    clock_icon: {
      marginRight: "2px",
      color: "black",
    },
    spacer: {
      flexGrow: 1,
    },
    dropdown: {
      width: ({ width, popperWidth }) => popperWidth || width,
      marginLeft: "-36px",
      minWidth: "147.59px",
      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.12)",
      borderRadius: "5px",
      overflowY: "overlay",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#A09A92",
        borderRadius: "12px",
      },
    },
    input: {
      "&:focus": {
        backgroundColor: ({ error }) => (error ? "#FBEBEA" : "#EBE9F6"),
      },
      fontSize: ({ showPlaceholder }) => (showPlaceholder ? "14px" : "16px"),
      lineHeight: "20px",
      fontWeight: 400,
      color: ({ showPlaceholder }) => (showPlaceholder ? "#8A8A8A" : "#272522"),
    },
    input_placeholder: {
      fontWeight: 400,
    },
    error_wrapper: {
      marginTop: "4.5px",
      display: "flex",
      alignItems: "center",
      gap: "6.64px",
      width: "100%",
      color: "#BF2600",
      fontSize: "14px",
      lineHeight: "17px",
      "& svg": {
        width: "18.8px",
        color: "#BF2600",
      },
    },
    clearIcon: {
      color: theme?.palette?.secondary?.main,
      cursor: "pointer",
    },
    hide: {
      visibility: "hidden",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
