import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(
  (theme) => ({
    mobileLayout: {
      padding: ({ padding }) => (padding ? padding : "16px"),
      // height: ({ showBottomNav }) =>
      //   showBottomNav ? "calc(100vh - 144px)" : "calc(100vh - 64px)",
      // overflow: "scroll",
      // position: "fixed",
      // top: "64px",
      width: "100%",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "16px",
    },
    title: {
      color: theme?.palette?.secondary?.main,
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "20px",
      marginBottom: "32px",
    },
  }),
  { name: "CreatorTool" }
);

const MobileLayout = ({
  children,
  title,
  secondaryComponent,
  paddingBottom = "16px",
  paddingMobile,
  description,
  showFixedBars,
}) => {
  const { showBottomNav } = useSelector((state) => state.global);
  const classes = useStyles({ padding: paddingMobile, showBottomNav });
  const showDescription = Boolean(description);
  const showSecondaryComponent = Boolean(secondaryComponent);

  return (
    <Box
      className={classes.mobileLayout}
      style={{ paddingBottom: paddingBottom }}
    >
      {showFixedBars && title && (
        <Typography className={classes.title} component="h1">
          {title}
        </Typography>
      )}

      {showDescription || showSecondaryComponent ? (
        <Box className={classes.header}>
          {showDescription && (
            <Typography className={classes.description} component="p">
              {description}
            </Typography>
          )}
          {showSecondaryComponent && secondaryComponent}
        </Box>
      ) : null}
      {children}
    </Box>
  );
};

export default MobileLayout;
