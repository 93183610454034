import React from "react";
import DesktopTimePicker from "./components/DesktopTimePicker";
import NativeTimePicker from "./components/NativeTimePicker";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { is_desktop } from "utils/Utils";

const ExlyTimePicker = ({ value, ...rest }) => {
  const TimePicker = is_desktop ? DesktopTimePicker : NativeTimePicker;

  return <TimePicker {...rest} value={value || ""} />
};

export default withComponentLibraryTheme(ExlyTimePicker);
