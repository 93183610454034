import React, { useCallback } from "react";
import { Field } from "react-final-form";
import moment from 'moment';
import { format, isValid } from "date-fns";

import ExlyDatePicker from "common/form/ExlyDatePicker";

import useStyles from "../ExlyTable.styles";
import constants from "constants/constants";

const ExlyTableFilterDatePicker = ({
  placeholder,
  source,
  filterValues,
  setFilters,
}) => {
  const classes = useStyles();

  const onChange = useCallback(
    (value) => {
      setFilters({
        ...filterValues,
        [source]: value,
      });
    },
    [filterValues, source]
  );

  return (
    <Field
      component={ExlyDatePicker}
      name={source}
      placeholder={placeholder}
      format={(value) => (value ? moment(value).toDate() : null)}
      parse={(value) => {
        const formattedValue = isValid(value)
          ? format(new Date(value), constants.internationalDateFormat)
          : null;
        onChange(formattedValue);
        return formattedValue;
      }}
      popperClassName={classes.datePickerPopperClassName}
      inputProps={{
        classes: { notchedOutline: classes.noBorder },
        inputClassName: classes.inputFilter,
      }}
    />
  );
};

export default ExlyTableFilterDatePicker;
