import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      gap: ({ is_desktop }) => is_desktop ? "13px" : "4px",
    },
    commentAvatar: {
      width: "49px",
      height: "49px",
      fontSize: "16px",
    },
    rightSection: {
      flex: 1,
    },
    detailsWrapper: {
      background: ({ showReplies }) => showReplies ? "rgba(220, 217, 242, 0.5)" : "rgba(207, 205, 201, 0.4)",
      borderRadius: "10px",
      padding: "12px 12px 20px 12px",
    },
    commentHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.basic.black,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "17px",
      wordBreak: "break-word",
    },
    commenterName: {
      textTransform: "capitalize",
    },
    commentTime: {
      color: theme.palette.secondary.shade_600,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "12px",
      marginLeft: "10px",
    },
    iconButton: {
      padding: 0,
    },
    commentContent: {
      marginTop: "16px",
      fontSize: "14px",
      color: theme.palette.secondary.main,
    },
    popoverPaper: {
      boxShadow: "0px 4px 4px rgba(14, 13, 12, 0.16)",
      borderRadius: "5px",
      background: theme.palette.basic.white,
      padding: "8px 0",
    },
    actionItem: {
      fontSize: "14px",
      lineHeight: "17px",
      padding: "4px 9px",
      '&:hover': {
        background: "#E7E6E4",
        cursor: "pointer",
      },
    },
    "@media (max-width:780px)": {
      commentAvatar: {
        width: "29px",
        height: "29px",
        fontSize: "12px",
      },
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
