import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    helperTextWrapper: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      gap: theme.spacing(3),
    },
    errorIcon: {
      width: theme.spacing(5),
      height: "14px",
      color: theme.palette.basic.danger_red,
    },
    helperText: {
      fontSize: "11px",
      lineHeight: "14px",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      color: theme?.palette?.basic?.danger_red,
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
