import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    statsCardWrapper: {
      width: "100%",
      padding: "12px 16px 14px 16px",
      display: "flex",
      minHeight: "75px",
      alignItems: "center",
      backgroundColor: ({ color }) => theme?.palette[color].shade_50,
    },
    statsIcon: {
      marginRight: "14px",
      color: ({ color }) => theme?.palette[color].main,
      fill: ({ color }) => theme?.palette[color].main,
    },
    statsDetails: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      gap: "4px",
    },
    statsTitle: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      color: ({ color }) => theme?.palette[color].main,
      wordBreak: "break-word",
    },
    statsSubTitle: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      marginBottomL: "2px",
      color: ({ color }) => theme?.palette[color].main,
      wordBreak: "break-word",
      opacity: "0.7",
    },
    statsNumber: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      color: ({ color }) => theme?.palette[color].main,
      wordBreak: "break-all",
    },
    statsLink: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: theme?.palette?.secondary.main,
      textDecoration: "underline",
      cursor: "pointer",
      alignSelf: "flex-end",
    },
    titleWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    titleSubtitleWrapper: {
      display: "flex",
      flexDirection: "column",

      gap: "2px",
    },
    toolTipIcon: {
      fontSize: "16px",
      lineHeight: "22px",
      color: ({ color }) => theme?.palette[color].main,
    },
    card_wrapper_mb: {
      width: "100%",
      padding: "10px",
      borderRadius: "12px",
      boxShadow: "0px 1px 1px rgba(73, 58, 177, 0.08)",
      backgroundColor: ({ color }) => theme?.palette[color].shade_50,
    },
    stats_icon_mb: {
      color: ({ color }) => theme?.palette[color].main,
    },
    icon_wrapper_mb: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "14px",
      width: "100%",
    },
    info_icon_wrapper_mb: {
      width: "20px",
      height: "20px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
    },
    stats_title_mb: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "17px",
      color: ({ color }) => theme?.palette[color].main,
      wordBreak: "break-word",
    },
    stats_subtitle_mb: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "9px",
      lineHeight: "10px",
      marginBottomL: "2px",
      color: ({ color }) => theme?.palette[color].main,
      wordBreak: "break-word",
      opacity: "0.9",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
