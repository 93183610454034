import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Box, Tooltip } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "react-redux";

import useStyles from "./StatsCard.styles";
import PeopleIcon from "@material-ui/icons/People";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const StatsCard = ({
  color = "primary",
  title,
  statsNumber,
  showLinkText = true,
  linkText = "View Chart",
  onClick = () => null,
  Icon = PeopleIcon,
  tooltip = null,
  className,
  filterDays,
}) => {
  const isMediumSize = useMediaQuery("(min-width: 767px)");
  const isLargeSize = useMediaQuery("(min-width: 1042px)");
  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const showFullSizeCard = isSidebarOpen ? isLargeSize : isMediumSize;

  const classes = useStyles({ color });
  const [open, setOpen] = useState(false);

  const onCardClick = () => {
    if (open) setOpen(false);
    else onClick();
  };

  const onOpenTooltip = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  return showFullSizeCard ? (
    <Card
      className={[classes.statsCardWrapper, className].join(" ")}
      elevation={0}
    >
      <Icon className={classes.statsIcon} />
      <Box className={classes.statsDetails}>
        <Box className={classes.titleSubtitleWrapper}>
          <Box className={classes.titleWrapper}>
            <Typography className={classes.statsTitle} component="span">
              {title}
            </Typography>
            {!!tooltip && (
              <Tooltip title={tooltip} color="primary" arrow>
                <InfoOutlinedIcon className={classes.toolTipIcon} />
              </Tooltip>
            )}
          </Box>
          {filterDays && (
            <Typography className={classes.statsSubTitle} component="span">
              Last {filterDays} Days
            </Typography>
          )}
        </Box>

        <Typography className={classes.statsNumber} component="span">
          {statsNumber}
        </Typography>
      </Box>

      {showLinkText && (
        <Typography
          className={classes.statsLink}
          onClick={onClick}
          component="span"
        >
          {linkText}
        </Typography>
      )}
    </Card>
  ) : (
    <Card
      className={classnames(
        classes.card_wrapper_mb,
        className,
        "row no-gutters"
      )}
      elevation={0}
      onClick={onCardClick}
    >
      <Box className={`${classes.icon_wrapper_mb}`}>
        <Icon className={classes.stats_icon_mb} />
        {!!tooltip && (
          <div className={classes.info_icon_wrapper_mb}>
            <Tooltip title={tooltip} color="primary" arrow>
              <InfoOutlinedIcon
                onClick={onOpenTooltip}
                className={classes.toolTipIcon}
              />
            </Tooltip>
          </div>
        )}
      </Box>

      <Typography
        className={`col-11 ml-1 ${classes.statsNumber}`}
        component="div"
      >
        {statsNumber}
      </Typography>

      <Typography
        className={`col-12 ${classes.stats_title_mb}`}
        component="div"
      >
        {title}
      </Typography>
      <Typography className={classes.stats_subtitle_mb} component="span">
        Last {filterDays} Days
      </Typography>
    </Card>
  );
};

export default StatsCard;

StatsCard.propTypes = {
  color: PropTypes.any,
  title: PropTypes.string,
  statsNumber: PropTypes.string,
  showLinkText: PropTypes.bool,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
  Icon: PropTypes.any,
  tooltip: PropTypes.any,
  className: PropTypes.string,
};
