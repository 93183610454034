import React, { useEffect, useState } from "react";
import { Button } from "@my-scoot/component-library-legacy";
import ExlyModal from "../ExlyModal";
import useFeatureOnboardingChecker from "./useFeatureOnboardingChecker";
import style from "./FeatureOnboarding.module.css";

const FeatureOnboarding = ({ featureKey, slides, onClose }) => {
  const { isOnboarded, flagLoading, handleFeatureOnboardChange } =
    useFeatureOnboardingChecker({ featureKey });
  const [showModal, setShowModal] = useState(false);
  const [currSlide, setCurrSlide] = useState(0);
  const isLast = currSlide === slides.length - 1;

  useEffect(() => {
    if (flagLoading) return;

    if (!isOnboarded) {
      setTimeout(() => {
        setShowModal(true);
      }, 500);
    }
  }, [isOnboarded, flagLoading]);

  const onNext = () => {
    if (isLast) {
      if (onClose) onClose();
      setShowModal(false);
      handleFeatureOnboardChange(true);
    } else {
      setCurrSlide(currSlide + 1);
    }
  };

  return (
    <>
      {/* Kept this button, even though not required, for debugging in this modal  */}
      {/* <Button onClick={() => handleFeatureOnboardChange(false)}>
        click me
      </Button> */}
      <ExlyModal
        open={showModal}
        customHeader={<></>}
        padded={false}
        customFooter={
          <div className={style.modalFooter}>
            <Button onClick={onNext} fullWidth>
              {isLast ? "Continue" : "Next"}
            </Button>
          </div>
        }
        modal_props={{ modalPaperClassName: style.desktopModalPaper }}
        mobile_modal_props={{
          paperClassName: style.modalPaper,
          escapeKeyDownClose: true,
        }}
        className={style.modalPaper}
      >
        {slides[currSlide]}
      </ExlyModal>
    </>
  );
};

export default FeatureOnboarding;
