import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    modal_paper: {
      width: "500px",
    },
    desktop_content: {
      padding: "8.17px 30px 24px 24px",
    },
    mobile_content: {
      padding: "0.17px 21px 13.12px 16px",
    },
    card: {
      boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.08)",
      borderRadius: "10px",
      border: `1px solid #E5E5E5`,
      marginTop: "16px",
      padding: "20px 19px 19px 19px",
      display: "flex",
      alignItems: "center",
      gap: "21.39px",
    },
    cardWrapper: {
      display: "flex",
      gap: "28px",
      alignItems: "center",
      flex: 1,
    },
    card_description: {
      fontWeight: 400,
      fontSize: "12px",
      opacity: "0.6",
    },
    card_content: {
      flexGrow: 1,
      fontSize: "14px",
      lineHeight: "17px",
      color: theme?.palette?.secondary.main,
    },
    card_title: {
      fontWeight: 500,
      marginBottom: "6px",
      fontSize: "14px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
