import React from "react";
import classnames from "classnames";

import InfoIcon from "@material-ui/icons/InfoOutlined";

import styles from "./ExlyNote.module.css";

export const ExlyNote = ({ icon, label, className }) => {
  return (
    <div className={classnames(styles.infoRoot, className)}>
      {icon || <InfoIcon />}
      {label}
    </div>
  );
};
