export const CourseCommentType = {
  TEXT: 1,
  IMAGE: 2,
  HTML: 3,
};

export const BLOG_FIELDS = {
  COMMENTOR: "commenter",
  COMMENT: "content",
  ID: "id",
  type: "type",
};

export const RECORDED_CONTENT_FIELDS = {
  COMMENTOR: "commenter_name",
  COMMENT: "comment",
  ID: "uuid",
  type: "type",
};
