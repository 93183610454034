import React from "react";

import { Box, Typography } from "@material-ui/core";
import { Button } from "@my-scoot/component-library-legacy";
import ExlyModal from "../ExlyModal";

import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useNotifications } from "utils/hooks";
import { useStyles } from "./index.styles";

const ExlyShareLinkModal = ({
  link,
  copyLinkBtnText,
  platformContainerText,
  platforms,
  showCopyLink = true,
  ...restProps
}) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  const { notify } = useNotifications();

  return (
    <ExlyModal
      className={classes.root}
      modal_props={{
        customFooter: <></>,
      }}
      mobile_modal_props={{
        customFooter: <></>,
      }}
      {...restProps}
    >
      <Box className={classes.linkWrapper}>
        <a
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
          href={link}
        >
          {link}
        </a>
      </Box>

      {showCopyLink && (
        <Button
          className={classes.copyBtn}
          buttonWrapperClassName={classes.buttonWrapperClassName}
          classes={{ label: classes.drawerFooterBtnLabel }}
          color="secondary"
          variant="outlined"
          fullWidth
          onClick={() => {
            if (navigator && navigator.clipboard) {
              navigator.clipboard.writeText(link);
              notify("Link Copied!", "info");
            }
          }}
        >
          <FileCopyOutlined />
          <Typography
            component="span"
            className={classes.drawerFooterBtnContent}
          >
            {copyLinkBtnText || "Copy link"}
          </Typography>
        </Button>
      )}

      <Box className={classes.platformContainer}>
        <Typography className={classes.platformContainerTitle} component={Box}>
          {platformContainerText || "Share on Social Media"}
        </Typography>

        <Box className={classes.platforms}>
          {platforms?.map(({ icon, ...restPlatformProps }) => (
            <img
              key={`${icon}`}
              className={classes.platformIcon}
              src={icon}
              {...restPlatformProps}
              alt={`Platform-Icon`}
            />
          ))}
        </Box>
      </Box>
    </ExlyModal>
  );
};

export default ExlyShareLinkModal;
